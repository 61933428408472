import { makeStyles } from '@mui/styles';
import * as colors from "../../../../constants/colors";
import { aptapColors } from '../../../../design/materials/exports';

const styles = makeStyles(theme => ({
    root: {
        backgroundColor: aptapColors.BACKGROUND_BLUE,
        maxWidth: 1920,
        margin: '0 auto',
        paddingTop: 128,
        paddingBottom: 128,
        [theme.breakpoints.down('md')]: {
            paddingTop: 32,
            paddingBottom: 32
        },
    },
    left: {
        color: '#ffffff',
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(5),
        [theme.breakpoints.down('lg')]: {
            paddingLeft: theme.spacing(15),
            paddingRight: theme.spacing(0),
        },
        [theme.breakpoints.down('lg')]: {
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(5),
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
        },
    },
    right: {
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(25),
        [theme.breakpoints.down('lg')]: {
            paddingLeft: theme.spacing(15),
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(0),
        },
    },
    title: {
        padding: theme.spacing(20, 0, 4),
        fontSize: '2.5rem',
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.7rem',
        },
    },
    contactUsButton: {
        fontFamily: 'Work Sans',
        fontWeight: 500,
        fontSize: 18,
        color: 'white',
        backgroundColor: '#7744E2',
        width: 228,
        height: 40,
        boxShadow: '0px 0px 2px rgba(0, 14, 40, 0.04), 0px 2px 4px rgba(92, 100, 116, 0.16)',
        textTransform: 'none',
        borderRadius: 52,
        background: 'white',
        '&:hover': {
            background: colors.APTAPPRIMARY,
            boxShadow: 'none',
        },
        [theme.breakpoints.down('lg')]: {
            width: 128,
            height: 32,
            marginBottom: 24,
        },
    },
    bankImg: {
        height: '500px',
        [theme.breakpoints.down('md')]: {
            height: '400px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    header: {
        color: '#7744E2',
        fontSize: 48,
        lineHeight: "56px",
        fontWeight: 700,
        fontFamily: 'Work Sans',
        paddingBottom: 32,
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
            lineHeight: "28px",
            fontWeight: 700,
            textAlign: 'center',
        },
    },
    bodyText: {
        color: '#586072',
        fontSize: 24,
        fontWeight: 400,
        fontFamily: 'Work Sans',
        lineHeight: '28px',
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            lineHeight: '18px',
        },
    }
}));

export default styles;