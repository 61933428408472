import { useNavigate } from 'react-router';

// The useNavigate hook has been replacing withRouter in the newest versions
// of react-router, so the function below replaces withRouter in every file
// that uses it.

export const withRouter = (Component) =>{
    const Wrapper = (props) =>{
        const history = useNavigate();
        return <Component history={history} {...props}/>
    } 
    return Wrapper;
}
