import { makeStyles } from "@mui/styles";
import { aptapColors } from "../../../design/materials/exports";

const styles = makeStyles((theme) => ({
  // main styles
  root: {},
  main: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
  },
  questionContainer: {
    width: "1520px",
    padding: "32px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "24px 0",
      width: "unset",
    },
  },
  questions: {
    padding: 0,
    [theme.breakpoints.down("md")]: {
      padding: "0px 16px",
    },
  },
  accordion: {
    marginBottom: "20px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px",
    },
  },
  statsContainer: {
    backgroundColor: aptapColors.BACKGROUND_BLUE,
    margin: "0 auto",
    padding: "128px 0px 0px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "32px 8px 0",
    },
  },
  stats: {
    padding: "0px 16px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "32px",
    },
  },

  // typography styles
  subHeader1Text: {
    color: "#ffffff",
    fontFamily: "Work Sans",
    fontWeight: "600",
    fontStyle: "bold",
    fontSize: "48px",
    lineHeight: "48px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
      lineHeight: "30px",
    },
  },
  bodyText: {
    color: "#586072",
    fontFamily: "Work Sans",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: 24,
    lineHeight: "28px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      lineHeight: "18px",
    },
  },
  bodyText2: {
    color: "#586072",
    fontFamily: "Work Sans",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "25px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      lineHeight: "18px",
    },
  },
  statText: {
    color: (props) =>
      props.isMobile ? aptapColors.DARK_GREY : aptapColors.BILL_BLUE,
    fontFamily: "Work Sans",
    fontWeight: 500,
    fontSize: 64,
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
      lineHeight: "36px",
      marginBottom: 8,
    },
  },
  subHeader2Text: {
    color: aptapColors.DARK_GREY,
    fontFamily: "Work Sans",
    fontWeight: 400,
    fontSize: 34,
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
      lineHeight: "18px",
    },
  },

  // button styles
  button: {
    color: "white",
    width: "200px",
    height: "48px",
    boxShadow: "none",
    margin: "0 auto",
    marginTop: "16px",
    textTransform: "unset !important",
    borderRadius: 16,
    backgroundColor: "#000e28",
    "&:hover": {
      backgroundColor: "#002363",
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: "140px",
      height: "32px",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    fontFamily: "Work Sans",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "19px",
    lineHeight: "14px",
  },
  faqContainer: {
    backgroundColor: aptapColors.LIGHT_GREY,
    paddingTop: 64,
    [theme.breakpoints.down("md")]: {
      paddingTop: 32,
    },
  },
}));

export default styles;
