import React from "react";

// Material-UI
import useStyles from "./FooterStyles";
import Grid from '@mui/material/Grid';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from "@mui/material/Typography";
import Link from '@mui/material/Link';
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import ContactBottom from "../../contact/ContactBottom";

import MailOutlineIcon from '@mui/icons-material/MailOutline';

import ApTapLogo from '../../../../assets/images/aptap_logo_2022.png';

import LinkedInIcon from '../../../../assets/images/social/linkedin_2022_grey.png';
import FacebookIcon from '../../../../assets/images/social/facebook_2022_grey.png';
import TwitterIcon from '../../../../assets/images/social/twitter_2022_grey.png';
import InstagramIcon from '../../../../assets/images/social/instagram_2022_grey.png';

var path = window.location.pathname
var mixpanel = require('mixpanel-browser');

function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles({ isMobile });

  return (
    <>
      <Grid container justifyContent="center" className={classes.contactForm}>
        <Grid container item xs justifyContent="center"><ContactBottom /></Grid>
      </Grid>
      <Grid container direction="column" className={classes.footer}>
        <Grid container justifyContent={isMobile ? null : 'space-between'} className={classes.footerLinks}>
          {!isMobile && (
            <Grid container item xs={isMobile ? 12 : 3} direction="column" alignItems={isMobile ? 'center' : null} className={classes.footerLinkItem}>
              <Grid item><img src={ApTapLogo} alt="ApTap's logo" style={{ maxWidth: 140, paddingBottom: 16 }} /></Grid>
              <Grid container item justifyContent="space-between" wrap="nowrap" style={{ maxWidth: 160 }}>
                <ButtonBase href="https://www.linkedin.com/company/aptap-ltd" className={classes.socialIconButton}><img src={LinkedInIcon} alt="LinkedIn's icon" /></ButtonBase>
                <ButtonBase href="https://www.facebook.com/ApTapUK" className={classes.socialIconButton}><img src={FacebookIcon} alt="Facebook's icon" /></ButtonBase>
                <ButtonBase href="https://twitter.com/aptapuk" className={classes.socialIconButton}><img src={TwitterIcon} alt="Twitter's icon" /></ButtonBase>
                <ButtonBase href="https://www.instagram.com/aptapuk" className={classes.socialIconButton}><img src={InstagramIcon} alt="Instagram's icon" /></ButtonBase>
              </Grid>
            </Grid>
          )}

          <Grid container item xs={isMobile ? 6 : 3} direction="column" className={classes.footerLinkItem}>
            <Grid item><Typography className={classes.footerLinkTitle}>Company</Typography></Grid>
            <Grid item>
              <Link className={classes.link} onClick={() => { mixpanel.track("Blog", { "navigatedFrom": window.location.href + " (footer)" }); }} href="/blog"><Typography className={classes.footerLinkText}>Blog</Typography></Link>
            </Grid>
            <Grid item>
              <Link className={classes.link} onClick={() => { mixpanel.track("About Us", { "navigatedFrom": window.location.href + " (footer)" }); }} href="/about-us"><Typography className={classes.footerLinkText}>About Us</Typography></Link>
            </Grid>
          </Grid>

          <Grid container item xs={isMobile ? 6 : 3} direction="column" className={classes.footerLinkItem}>
            <Grid item><Typography className={classes.footerLinkTitle}>Legal</Typography></Grid>
            <Grid item>
              <Link className={classes.link} onClick={() => { mixpanel.track("Privacy Policy Page", { "navigatedFrom": window.location.href + " (footer)" }); }} href="/privacy-policy"><Typography className={classes.footerLinkText}>Privacy Policy</Typography></Link>
            </Grid>
            <Grid item>
              <Link className={classes.link} onClick={() => { mixpanel.track("Terms and Conditions Page", { "navigatedFrom": window.location.href + " (footer)" }); }} href="/terms-and-conditions"><Typography className={classes.footerLinkText}>Terms & Conditions</Typography></Link>
            </Grid>
          </Grid>

          <Grid container item xs={isMobile ? 12 : 3} direction="column" className={classes.footerLinkItem}>
            <Grid item><Typography className={classes.footerLinkTitle}>Contact Us</Typography></Grid>
            <Grid container item wrap="nowrap" alignItems="center"><MailOutlineIcon style={{ marginRight: 12 }} />
              <Link className={classes.link} href="mailto:contact@aptap.co.uk?">
                <Typography className={classes.footerLinkText} style={{ minHeight: 'unset' }}>
                  contact@aptap.co.uk
                </Typography>
              </Link>
            </Grid>
          </Grid>

          {isMobile && (
            <Grid container item xs={isMobile ? 12 : 3} direction="column" alignItems={isMobile ? 'center' : null} className={classes.footerLinkItem}>
              <Grid item><img src={ApTapLogo} alt="ApTap's logo" style={{ width: 192, paddingBottom: 16 }} /></Grid>
              <Grid container item justifyContent="space-between" wrap="nowrap" style={{ maxWidth: 192 }}>
                <ButtonBase href="https://www.linkedin.com/company/aptap-ltd" className={classes.socialIconButton}><img src={LinkedInIcon} alt="LinkedIn's icon" /></ButtonBase>
                <ButtonBase href="https://www.facebook.com/ApTapUK" className={classes.socialIconButton}><img src={FacebookIcon} alt="Facebook's icon" /></ButtonBase>
                <ButtonBase href="https://twitter.com/aptapuk" className={classes.socialIconButton}><img src={TwitterIcon} alt="Twitter's icon" /></ButtonBase>
                <ButtonBase href="https://www.instagram.com/aptapuk" className={classes.socialIconButton}><img src={InstagramIcon} alt="Instagram's icon" /></ButtonBase>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item><hr className={classes.divider} /></Grid>
        {
          path === "/tsb" ||  path === "/stepladder" || path === "/mobileapp"
            ? <Grid item>
              <Typography className={classes.footerSubstantiation}>
                *Based on estimated broadband savings from switches between 1st January 2022 and 1st June 2022.
              </Typography>
            </Grid>
            : <></>
        }
        <Grid item><Typography className={classes.footerLegal}>
          ApTap Ltd is registered with the Financial Conduct Authority (FRN: 850828) as an agent of TrueLayer Limited, who is providing the regulated Account Information Service, and is authorised by the Financial Conduct Authority (FRN: 901096) under the Payment Services Regulations 2017 and Electronic Money Regulations 2011
        </Typography></Grid>
      </Grid>
    </>
  );
}

export default Footer;
