import React from 'react';

// Components
import Header from '../components/header/Header';
import MobileHeader from '../components/mobileHeader/MobileHeader';
import Footer from '../components/footer/Footer';
import Banner from './banner/Banner';
import BankContent from './content/BankContent';

// Material-UI
import useStyles from './Banks-style';
import Hidden from '@mui/material/Hidden';

function Banks() {
  const classes = useStyles();
  const contactRef = React.createRef();
  const isMobile = window.innerWidth < 960;

  const scrollToContactForm = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Hidden mdDown>
          <Header />
        </Hidden>
        <Hidden mdUp>
          <MobileHeader scrollToContactForm={scrollToContactForm} />
        </Hidden>
        <Banner scrollToContactForm={scrollToContactForm} />
      </div>
      <BankContent />
      <div ref={contactRef} className={classes.bottom}>
        <section id="footer">
          <Footer isMobile={isMobile} />
        </section>
      </div>
    </div>
  );
}

export default Banks;
