import { Component } from 'react';
import GatewayService from './services/gatewayService';

import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { withTheme } from "@mui/styles";
import { useStyles } from './ErrorBoundaryStyles';

import ErrorBoundaryImage from './assets/icons/error_boundary.png';

var mixpanel = require('mixpanel-browser');

const ErrorBoundaryComponent = () => {
    const classes = useStyles();

    const openSupport = () => {
        mixpanel.track('Contact Support Open', { NavigatedFrom: 'Error Boundary page' });

        const tidioIframe = document.getElementById('tidio-chat-iframe');
        if (tidioIframe) {
            tidioIframe.style.visibility = 'visible';
            tidioIframe.contentDocument.getElementById('button-body').click();
        }
    }

    const goBack = () => {
        if (location.pathname.includes('/marketplace/tsb/broadband')) window.location.href = '/marketplace/tsb/home-broadband';
        else if (location.pathname.includes('/marketplace/tsb/energy')) window.location.href = '/marketplace/tsb/home-energy';
        else if (location.pathname.includes('/marketplace/tsb/mobile')) window.location.href = '/marketplace/tsb/home-mobile';
        else if (location.pathname.includes('/marketplace/tsb/sim')) window.location.href = '/marketplace/tsb/home-mobile';
        else if (location.pathname.includes('/tsb/broadband')) window.location.href = '/tsb/home-broadband';
        else if (location.pathname.includes('/tsb/energy')) window.location.href = '/tsb/home-energy';
        else if (location.pathname.includes('/tsb/mobile')) window.location.href = '/tsb/home-mobile';
        else if (location.pathname.includes('/tsb/sim')) window.location.href = '/tsb/home-mobile';
        else window.location.href = '/';
    }

    return (
        <Stack justifyContent='space-between' alignItems='center' className={classes.container}>
            <Stack alignItems='center' style={{ padding: '60px 54px' }}>
                <img src={ErrorBoundaryImage} className={classes.image} />

                <Typography className={classes.header}>
                    Oops, something went wrong
                </Typography>

                <Typography className={classes.description}>
                    Your issue has been logged. Please try again later or contact our support team for more help.
                </Typography>
            </Stack>

            <Stack alignItems='center' style={{ width: '100%', padding: '0 28px 22px' }}>
                <Typography onClick={openSupport} className={classes.contactSupportText}>
                    Contact support
                </Typography>

                <ButtonBase onClick={goBack} className={classes.backButton}>
                    <Typography className={classes.backText}>
                        Back to home
                    </Typography>
                </ButtonBase>
            </Stack>
        </Stack>
    );
}

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorStack: null,
            info: ''
        }
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.log("ErrorBoundary - Error:", error.stack);
        console.log("ErrorBoundary - Info:", info);
        if (info?.componentStack) {
            if (info.componentStack.includes('BankLandingPage')) mixpanel.track('Something Went Wrong', { Page: 'Home' });
            else if (info.componentStack.includes('Broadband')) mixpanel.track('Something Went Wrong', { Page: 'Broadband' });
            else if (info.componentStack.includes('Sim')) mixpanel.track('Something Went Wrong', { Page: 'Sim' });
            else if (info.componentStack.includes('Mobile')) mixpanel.track('Something Went Wrong', { Page: 'Mobile' });
            else if (info.componentStack.includes('ApTapAboutUs')) mixpanel.track('Something Went Wrong', { Page: 'About Us' });
            else mixpanel.track('Something Went Wrong');
        }
        this.setState({ hasError: true, errorStack: error.stack, info: JSON.stringify(info) });
        GatewayService.logError({ error: error.stack, info: JSON.stringify(info), feedback: '' }).then(() => {
            console.log("ErrorBoundary - Log is succeed");
        });
    }

    render() {
        if (this.state.hasError) return <ErrorBoundaryComponent />;
        return <>{this.props.children}</>;
    }
}

export default withTheme(ErrorBoundary);
