import React, { Fragment } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

import BlogCard from "../card/BlogCard";

// Material-UI
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Hidden from '@mui/material/Hidden';
import { Button, CardMedia, Link as WebLink } from "@mui/material";

import news_img_03 from '../../../../assets/images/landing_img/news_img_03.jpg';
import news_img_06 from '../../../../assets/images/landing_img/news_img_06.jpg';
import news_img_08 from '../../../../assets/images/landing_img/news_img_08.jpg';
import news_img_11 from '../../../../assets/images/landing_img/news_img_11.jpg';

import * as colors from '../../../../constants/colors';

var mixpanel = require('mixpanel-browser');

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1920,
    padding: '0 200px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '0 16px'
    },
  },
  filterContainer: {
    marginBottom: 16,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10
    }
  },
  filterButton: {
    fontSize: 20,
    fontWeight: 500,
    fontFamily: 'Work Sans',
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    }
  },
  filterButtonAll: {
    color: colors.APTAPPRIMARY,
    fontSize: 20,
    fontWeight: 500,
    fontFamily: 'Work Sans',
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    }
  },
  topArticle: {
    width: '100%',
    height: '100%'
  },
  topArticleCategory: {
    color: colors.APTAPPRIMARY,
    fontSize: 24,
    fontWeight: 400,
    fontFamily: 'Work Sans',
    minHeight: 26
  },
  topArticleTitle: {
    color: '#000E28',
    fontSize: 48,
    fontWeight: 500,
    fontFamily: 'Work Sans',
    lineHeight: '48px'
  },
  topArticleSummary: {
    color: '#5C6474',
    fontSize: 24,
    fontWeight: 400,
    fontFamily: 'Work Sans'
  },
  topArticleDate: {
    color: '#5C6474',
    fontSize: 24,
    fontWeight: 400,
    fontFamily: 'Work Sans'
  },
  news: {
    fontSize: 36,
    fontWeight: 600,
    fontFamily: 'Work Sans'
  },
  newsTitle: {
    color: '#000E28',
    fontSize: 24,
    fontWeight: 500,
    fontFamily: 'Work Sans'
  },
  topNewsImage: {
    height: 88
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      color: '#000E28',
    }
  }
}));

const Articles = ({ articles }) => {
  const classes = useStyles();

  const [allArticles, setAllArticles] = React.useState([]);
  const [filteredArticles, setFilteredArticles] = React.useState([]);
  const [articleCategories, setArticleCategories] = React.useState([]);
  const [currentCategory, setCurrentCategory] = React.useState(null);

  const topNews = [
    {
      title: "FIL Digital Graduation Day Experience",
      blurb: `ApTap is a personalised bill and subscription management tool that allows banking customers to cancel, compare, switch and sign up to services like energy, broadband and phone, all in-app, with just a few taps.`,
      image: news_img_11,
      link: "https://fintech-innovation-lab-london.relayto.com/e/fil-digital-graduation-day-experience-mvww9qbdxaim0/rjvLQ1PN19"
    },
    {
      title: "Imperial Enterprise Lab",
      blurb: `Our mission is to bring simplicity and control to managing bills and subscriptions with just a tap. We also want to build the world’s first Appstore for Subscriptions or an iTunes for Services. We believe this is something that could make a huge impact not only in the UK market, but also across the world.`,
      image: news_img_03,
      link: "https://www.imperialenterpriselab.com/casestudies/aptap/"
    },
    {
      title: "ApTap and TSB Launch Bill Management Pilot",
      blurb: `Today, ApTap announced it has launched a pilot with TSB for its bill management service as the Bank looks to provide more solutions for customers to help them manage their money with confidence. The pilot will allow TSB’s employees to test the solution before it’s made available to customers.`,
      image: news_img_06,
      link: "https://thefintechtimes.com/aptap-and-tsb-launch-bill-management-pilot/"
    },
    {
      title: "ApTap and TSB Launch Bill Management Pilot",
      blurb: `TSB Bank is launching a pilot for a bill management and utility switching service in partnership with white-label fintech provider ApTap.`,
      image: news_img_08,
      link: "https://www.finextra.com/newsarticle/36174/tsb-launches-bill-management-pilot"
    }
  ];

  React.useEffect(() => {
    let sortedArticles = articles.reverse().sort(function (articleA, articleB) {
      let dateA = new Date(articleA.published_at).getTime();
      let dateB = new Date(articleB.published_at).getTime();
      return dateB - dateA;
    });

    const categories = sortedArticles && sortedArticles.length > 0
      ? ['All', ...sortedArticles.filter(article => article.category != null).map(article => article.category.name).filter((article, index, orjArray) => orjArray.indexOf(article) === index)]
      : [];
    setAllArticles(sortedArticles);
    setArticleCategories(categories);
    setFilteredArticles(sortedArticles.filter(article => currentCategory != null ? article.category.name === currentCategory : true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles]);

  const changeCategory = (category) => {
    let curr = category === 'All' ? null : category;
    setCurrentCategory(curr);
    setFilteredArticles(allArticles.filter(article => curr != null ? article.category != null && article.category.name === curr : true)
      .sort(function (articleA, articleB) {
        let dateA = new Date(articleA.published_at).getTime();
        let dateB = new Date(articleB.published_at).getTime();
        return dateB - dateA;
      }));
  }

  const onTopArticleClick = () => {
    mixpanel.track("Article Page", { "articleId": allArticles[0].id });
  }

  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <Container maxWidth={false}>
          {allArticles.length > 0 && (
            <Grid container style={{ height: 512, marginBottom: 128 }}>
              <Grid container item xs={8} spacing={0}>
                <Grid container item xs={12} justifyContent="flex-start" spacing={4} className={classes.filterContainer}>
                  {articleCategories.map(category => <Grid item key={category}><Button className={classes.filterButton} onClick={() => changeCategory(category)}>{category}</Button></Grid>)}
                </Grid>
                <Grid item xs={6}>
                  <Link to={`article/${allArticles[0].id}`} onClick={onTopArticleClick}>
                    <CardMedia
                      className={classes.topArticle}
                      image={"https://blog.aptap.co.uk" + allArticles[0].image.url}
                      alt={"https://blog.aptap.co.uk" + allArticles[0].image.url}
                    />
                  </Link>
                </Grid>
                <Grid container item xs={6} direction="column" justifyContent="space-between" style={{ padding: '20px 32px 0' }}>
                  <Typography className={classes.topArticleCategory}>{allArticles[0].category?.name ? allArticles[0].category.name : ""}</Typography>
                  <Link to={`article/${allArticles[0].id}`} onClick={onTopArticleClick} className={classes.link}><Typography className={classes.topArticleTitle}>{allArticles[0].title}</Typography></Link>
                  <Typography className={classes.topArticleSummary}>{allArticles[0].summary}</Typography>
                  <Typography className={classes.topArticleDate}><Moment format="MMM DD YYYY">{allArticles[0].published_at}</Moment></Typography>
                </Grid>
              </Grid>
              <Grid container item xs={4} direction="column" justifyContent="space-between">
                <Grid item><Typography className={classes.news}>News</Typography></Grid>
                {topNews.map((news, newsIndex) => <Fragment key={newsIndex}>
                  <WebLink href={news.link} className={classes.link}><Grid container item>
                    <Grid item xs={5}><img src={news.image} className={classes.topNewsImage} alt='Top news illustration' /></Grid>
                    <Grid item xs={7}><Typography className={classes.newsTitle}>{news.title}</Typography></Grid>
                  </Grid>
                  </WebLink>
                  {newsIndex !== 3 && <Grid item style={{ width: '100%', height: 1, borderTop: `1px solid ${colors.DIM_GREY2}`, margin: '4px 0' }}></Grid>}
                </Fragment>)}
              </Grid>
            </Grid>
          )}
          <Grid container>
            {filteredArticles.map((article, i) => {
              return <Grid item md={4} xs={12} key={i}>
                <BlogCard article={article} key={`article__${article.id}`} />
              </Grid>
            })}
          </Grid>
        </Container>
      </Hidden>
      <Hidden mdUp>
        <Container>
          <Grid container justifyContent="center" className={classes.filterContainer}>
            {articleCategories.map((category, i) => <Grid item key={i}><Button className={category === 'All' ? classes.filterButtonAll : classes.filterButton} onClick={() => changeCategory(category)}>{category}</Button></Grid>)}
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item md={12}>
              {filteredArticles.map((article, i) => {
                return <BlogCard article={article} key={`article__${article.id}`} />;
              })}
            </Grid>
          </Grid>
        </Container>
      </Hidden>
    </div>
  );
};

export default Articles;