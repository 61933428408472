import { makeStyles } from "@mui/styles";
import { aptapColors } from "../../../../design/materials/exports";

const styles = makeStyles((theme) => ({
  bankContentContainer: {
    maxWidth: 1920,
    margin: "0 auto",
    padding: "0 200px",
    backgroundColor: aptapColors.BACKGROUND_BLUE,
    [theme.breakpoints.down("md")]: {
      padding: "0 8px",
    },
  },
  blockContainer: {
    marginBottom: 128,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      padding: "0 16px",
      marginBottom: 32,
    },
  },
  blockItem: {
    maxWidth: 485,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 24,
    },
  },
  blockIcon: {
    width: 156,
    height: 156,
    [theme.breakpoints.down("md")]: {
      width: 103,
      height: 103,
    },
  },
  blockTitle: {
    color: "#586072",
    fontSize: 36,
    fontWeight: 500,
    fontFamily: "Work Sans",
    padding: "16px 0 0",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
  },
  blockText: {
    color: "#586072",
    fontSize: 24,
    fontWeight: 400,
    fontFamily: "Work Sans",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
    },
  },
  header: {
    color: "#000E28",
    fontSize: 48,
    lineHeight: "52px",
    fontWeight: 700,
    fontFamily: "Work Sans",
    paddingBottom: 32,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      lineHeight: "24px",
      fontWeight: 600,
      textAlign: "center",
      paddingBottom: 24,
    },
  },
  bodyText: {
    color: "#5C6474",
    fontSize: 24,
    fontWeight: 400,
    fontFamily: "Work Sans",
    lineHeight: "28px",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
}));

export default styles;
