import React from "react";
import { useLocation } from "react-router-dom";

// Assets
import ApTapLogoHD from '../../../../assets/images/Header_ApTap_HD.png';

// Material-UI
import useStyles from './Header-style';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

import QrCodeModalDesktop from "../../qrcodemodal/QrCodeModalDesktop";

var mixpanel = require('mixpanel-browser');

const Header = () => {
  const classes = useStyles();
  const location = useLocation();

  const [modalOpen, setModalOpen] = React.useState(false);

  let page;

  switch (location.pathname) {
    case '/mobileapp':
      page = 'Mobile App';
      break;
    case '/privacy-policy':
      page = 'Privacy Policy';
      break;
    case '/terms-and-conditions':
      page = 'Terms & Conditions';
      break;
    default:
      page = 'Blog';
      break;
  }

  // Once Home.js has been refactored into a functional component,
  // we can put this function in it instead.
  const handleModalOpen = () => {
    setModalOpen(true);
    mixpanel.landing.track('Get The App Button', {
      Page: page,
      Position: 'Nav',
    });
  }

  const handleModalClose = () => setModalOpen(false);

  return (
    <div className={classes.root}>
      <QrCodeModalDesktop isMobile={false} currentPage={'ApTap'} modalOpen={modalOpen} handleModalClose={handleModalClose} />
      <AppBar position='static' color='default' className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Button
            onClick={() => window.location.href = "/"}
            className={classes.aptapButton}
          >
            <img src={ApTapLogoHD} className={classes.aptapLogo} alt="aptap logo" />
          </Button>

          <div className={classes.menu}>

            <div>
              <Button
                onClick={() => {
                  window.location.href = "/about-us";
                  mixpanel.track("About Us Page", { "navigatedFrom": window.location.href + " (header)" });
                }}
                className={classes.pageButton}
                style={{ marginLeft: "0px" }}
              >
                <Typography className={location.pathname === "/about-us" ? classes.menuTextSelected : classes.menuText}>
                  About Us
                </Typography>
                <span className={classes.imageMarked} />
                {location.pathname === "/about-us" && (
                  <span className={classes.pageIndicator} />
                )}
              </Button>

              <Button
                onClick={() => {
                  window.location.href = "/blog";
                  mixpanel.track("Blog Page", { "navigatedFrom": window.location.href + " (header)" });
                }}
                className={classes.pageButton}
              >
                  <Typography className={location.pathname === "/blog" ? classes.menuTextSelected : classes.menuText}>
                    Blog
                  </Typography>
                <span className={classes.imageMarked} />
                {location.pathname === "/blog" && (
                  <span className={classes.pageIndicator} />
                )}
              </Button>
            </div>
            <div className={classes.menuRightSection}>
              <Button
                variant="contained"
                className={classes.getTheAppButton}
                onClick={handleModalOpen}
              >
                Get the app
              </Button>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
