import React, { useEffect } from "react";
// import './pp-tcs.css';
// Components
import Header from "../components/header/Header";
import MobileHeader from "../components/mobileHeader/MobileHeader";
import Footer from "../components/footer/Footer";

// Material-UI
import useStyles from "./pp-tcs-style";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";

var mixpanel = require("mixpanel-browser");

function TandCs() {
  const classes = useStyles();

  useEffect(() => {
    mixpanel.landing.track("Terms & Conditions Page");
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <Hidden mdDown>
          <Header />
        </Hidden>
        <Hidden mdUp>
          <MobileHeader />
        </Hidden>
      </div>
      <div className={classes.main}>
        <Typography variant="h2" align="center" className={classes.title}>
          ApTap Terms &amp; Conditions
        </Typography>
        <Typography variant="body1" align="center" className={classes.text}>
          <b>Last updated November 2023</b>
        </Typography>

        <Typography variant="body1" className={classes.text}>
          <ol className={classes.ol}>
            <li className={classes.li}>
              <span className={classes.span}>
                <b>Introduction</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    These are the terms of use for ApTap, accessed via the
                    website at{" "}
                    <a href="https://www.aptap.co.uk">www.aptap.co.uk</a> ("
                    <b>Site</b>") and the associated mobile application ("
                    <b>App</b>"). ApTap’s free Site and App allows you to view
                    your bank accounts, bills and payments on our ApTap
                    dashboard and offers a switching service for certain
                    products via our Site and App (the "<b>Service</b>").
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    The Service is provided to you by the ApTap LTD, which is
                    registered in England and Wales under company number
                    11171584 and has its registered office at Unit 6 Queens
                    Yard, White Post Lane, London, England, E9 5EN. ApTap is
                    also registered with the ICO under reference number ZA569799
                    and is registered with the Financial Conduct Authority (Firm
                    Reference Number: 850828). We rely on other parties to help
                    us deliver the service – TrueLayer. Our privacy policy
                    explains how we share data with these parties.
                  </span>
                </li>
                <li className={classes.li}><span className={classes.span}>
                The Service is provided to you by the ApTap LTD, which is registered in England and Wales under company number 11171584 and has its 
                registered office at 124 City Road, London, England, EC1V 2NX. ApTap is also registered with the ICO under reference number ZA569799 and 
                is registered with the Financial Conduct Authority (Firm Reference Number: 850828).  We rely on other parties to help us deliver the 
                service – TrueLayer. Our privacy policy explains how we share data with these parties.
                </span></li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    TrueLayer provide the regulated Account Information Service
                    within the Service which means we can securely connect to
                    your bank account and identify your bills. They are
                    authorised by the FCA as an Authorised Payment Institution
                    (reference number: 793171). ApTap acts as an agent of
                    TrueLayer (FCA reference number: 850828).
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    These terms and conditions form our legally binding
                    agreement with you in relation to the Service (the “
                    <b>Terms</b>”). Any reference to “<b>you</b>” in these Terms
                    includes yourself and any person that uses the Service on
                    your behalf.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    If the bank or provider account you connect to our Service
                    is a joint account, you must have checked with the other
                    joint account holder(s) that they are happy for you to
                    connect that bank account and for us to see the information
                    about it and them.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    Please read these Terms carefully before using the Service.
                    By using the Service, you agree to be bound by these Terms
                    and agree to comply with them. If you do not agree to these
                    Terms, you must not use our Service.
                  </span>
                </li>
                <br></br>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Other Applicable Terms</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    These Terms incorporate our Privacy &amp; Cookie Policy
                    which sets out the terms on which we collect, process, share
                    and store any personal data we collect from you, or that you
                    provide to us. Our Cookie Policy sets out information about
                    the cookies on our Site. We will only use your personal data
                    as set out in our{" "}
                    <a
                      onClick={() => {
                        mixpanel.track("Privacy Policy Page", {
                          navigatedFrom: window.location.href,
                        });
                      }}
                      href="/privacy-policy"
                    >
                      Privacy &amp; Cookie Policy
                    </a>
                    .
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    To be able to access your banking information including
                    details of your bills, you must accept TrueLayer's terms and
                    conditions. You can find out what they do here:{" "}
                    <a href="https://truelayer.com">https://truelayer.com</a>
                  </span>
                </li>
                <br></br>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>How our Service works</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    Below is a brief explanation of how the Service works:
                  </span>
                  <ol className={classes.ol}>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        When you use the Service, we transfer you to TrueLayer
                        who retrieve your account information needed for the
                        Service. We then use that information to understand what
                        deals are available in your area and facilitate the
                        switching process.
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        If you wish to initiate a switch, you must accept the
                        terms of the new provider before we can proceed. If you
                        consent, we pass your relevant personal information onto
                        the new provider, who in turn will notify your previous
                        provider about the switch. Some providers may require
                        that you cancel their service personally or notify them
                        of the contract termination when making the switch. In
                        this case, please be aware that it is your
                        responsibility to do so. You must also pay any
                        termination charges to your existing provider levies if
                        they require it.
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        You will also need to confirm the details of any joint
                        account holder who will be signed up with any new
                        provider as well as you and have confirmed with them
                        that they are happy to proceed.
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        You must be an individual of at least 18 years of age
                        and resident in the United Kingdom to use the Service.
                      </span>
                    </li>
                    <br></br>
                  </ol>
                </li>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>You Authorise ApTap to Act on Your Behalf</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    By using the Service you authorise ApTap to act as your
                    representative (subject to your specific instructions) for
                    the sole purpose of:
                  </span>
                  <ol className={classes.ol}>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        Accessing, organising and automating your accounts,
                        bills and payments with third-party providers; and
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        Assisting in cancelling accounts or services or changing
                        providers where specifically requested by you.
                      </span>
                    </li>
                    <br></br>
                  </ol>
                </li>
                <li className={classes.li}>
                  <span className={classes.span}>
                    You acknowledge and agree that when ApTap accesses and
                    retrieves information from third parties relating to you,
                    ApTap is acting on your behalf and not as an agent of any
                    third party or other entity. You agree that the
                    authorisation that you grant us to act on your behalf may be
                    relied upon by third parties.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    We do not enter into any contract with third-party providers
                    on your behalf; we simply let them know that you would like
                    to switch to them and to which deal or offer. It&#39;s
                    always your decision whether or not to switch to any deals
                    or offers that we let you know about.
                  </span>
                </li>
                <br></br>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Your Account and Password</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    You agree that you are responsible for ensuring that any
                    user identification codes, passwords or any other piece of
                    information used by you as part of the Service remain
                    confidential. If you know or suspect of any authorised use
                    of this information, you agree to notify us immediately at{" "}
                    <a href="mailto: contact@aptap.co.uk">
                      contact@aptap.co.uk
                    </a>
                    . We will not be liable for any unauthorised use of your
                    account unless we have been at fault.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    We have the right to disable any user identification code or
                    password, whether chosen by you or allocated by us, at any
                    time, if in our reasonable opinion you have failed to comply
                    with any of the provisions of these Terms or if we think
                    your account has been compromised.
                  </span>
                </li>
                <br></br>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Intellectual Property Rights and Use of Material</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    We are the owner or the licensee of all intellectual
                    property rights on our Site and App (including our algorithm
                    and all material published on our Site and App). Those works
                    are protected by copyright, patent and design laws and
                    treaties around the world. All such rights are reserved and,
                    subject to the below, you may not reproduce, copy,
                    distribute, sell, sub-licence, store, or in any other manner
                    re-use content from our Site or App unless given express
                    written permission to do so by us.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    You may print off one copy and may download extracts of any
                    page(s) from our Site or App for your personal use. You may
                    draw the attention of others within your organisation to
                    content on our Site or App.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    You must not modify the paper or digital copies of any
                    materials you have printed off or downloaded in any way, and
                    you must not use any illustrations, photographs, video or
                    audio sequences or any graphics separately from any
                    accompanying text.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    Our status (and that of any identified contributors) as the
                    authors of content on our Site and App must always be
                    acknowledged.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    You must not use any part of the content on our Site or App
                    for commercial purposes without obtaining a licence to do so
                    from us or our licensors.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    If you print off, copy or download any part of our Site or
                    App in breach of these Terms, your right to use our Site and
                    App will cease immediately and you must, at our option,
                    return or destroy any copies of the materials you have made.
                    You agree that you will not use our intellectual property
                    rights in any way other than allowed under these Terms and
                    any infringement by you thereof will be a material breach of
                    these Terms.
                  </span>
                </li>
                <br></br>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>No Reliance on Information and Disclaimer</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    Our Service is provided for general information only. It is
                    not intended to amount to advice on which you should rely.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    Access to the content, and all services and products
                    associated with the Service is provided on an "as is" and
                    "as available" basis. We may suspend, withdraw, discontinue
                    or change all or any part the Service without notice.
                    However, we will complete any switches that you have
                    initiated through the Services. We will not be liable to you
                    if for any reason the Service is unavailable at any time or
                    for any period, although we do our best to make sure it is
                    always available (sometimes we need to carry out maintenance
                    though – if we are making updates and fixing bugs we always
                    aim to let you know beforehand).
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    Although we make reasonable efforts to update Service and
                    only offer existing available deals from third-party
                    providers, we make no representations, warranties or
                    guarantees, whether express or implied, that the content is
                    accurate, complete or up-to-date as we have no control over
                    the third-party providers. Deals and quotes may be withdrawn
                    or changed at the discretion of the third-party providers.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>In particular</span>
                  <ol className={classes.ol}>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        You acknowledge that we obtain our information from
                        third parties and we cannot, and do not, provide any
                        representations, guarantees, and/or warranties as to
                        quality, suitability for your purposes or needs,
                        compatibility, reliability, accuracy, completeness,
                        timeliness or use of information provided to us by any
                        third party and/or that is accessed or obtained by you
                        or otherwise through using our Service.
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        It is your responsibility to evaluate the quality,
                        suitability, accuracy, completeness and reliability of
                        this Service, information provided by any third party,
                        information accessed or obtained by you through using
                        our Services, and any information or content contained
                        therein.
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        We are therefore not responsible for your decision to
                        switch provider, sign up to any deal, or cancel any
                        services.
                      </span>
                    </li>
                    <br></br>
                  </ol>
                </li>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Liability</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    Nothing in these Terms excludes or limits our liability for
                    death or personal injury arising from our negligence, or our
                    fraud or fraudulent misrepresentation, or any other
                    liability that cannot be excluded or limited by English law.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    In the event that we suspect fraudulent activity relating to
                    your account in any way, you will be contacted directly via
                    email.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    If we fail to comply with these Terms, we are responsible
                    for loss or damage you suffer that is a foreseeable result
                    of our breach of these Terms or our negligence, but we are
                    not responsible for any loss or damage that is not
                    foreseeable. Loss or damage is foreseeable if it is an
                    obvious consequence of our breach or if it was contemplated
                    by you and us at the time you started to use the Service.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    We are not liable for business losses. We only supply the
                    Service for personal use. If you use the Service for any
                    commercial or business purpose we will have no liability to
                    you for any loss of profit, loss of business, business
                    interruption, or loss of business opportunity.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    We assume no responsibility for the content of websites
                    linked on our Site or App. Unless expressly stated, these
                    websites are not under our control. Such links should not be
                    interpreted as endorsement by us of those linked websites.
                    We will not be liable for any loss or damage that may arise
                    from your use of them.
                  </span>
                </li>
                <br></br>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>How We Make Money</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    We earn a commission every time you purchase a product,
                    switch or sign up to services through our Site or App. The
                    third party service provider pays us a commission, when the
                    switch or sign up has been confirmed, that varies based on
                    the product.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    We do not present deals in a way that is based on the
                    commission we make.
                  </span>
                </li>
                <br></br>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Viruses</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    We use multiple layers of security such as using tokens,
                    unique customer identifiers, API keys and encryption to
                    protect the Service. Our App is hosted in a VPC (virtual
                    private cloud) with only one internet facing port. This
                    means you need a VPN to make direct connections to any of
                    our micro-services. We also use OAuth 2.0 Security protocols
                    (the same as used in Open Banking) to secure information
                    being passed through our API&#39;s. Our database is 256-bit
                    encrypted and all data is also encrypted in flight. However,
                    we cannot guarantee that our Site or App will be secure or
                    free from bugs or viruses. You are responsible for
                    configuring your information technology, computer programmes
                    and platform in order to access our Site or App. You should
                    also use your own virus protection software.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    You must not misuse our Site or App by knowingly introducing
                    viruses, trojans, worms, logic bombs or other material which
                    is malicious or technologically harmful. You must not
                    attempt to gain unauthorised access to our Site or App, the
                    servers on which our Site or App are stored or any server,
                    computer or database connected to our Site or App. You must
                    not attack our Site or App via a denial-of-service attack or
                    a distributed denial-of service attack. A breach of this
                    provision is a criminal offence under the Computer Misuse
                    Act 1990. We will report any such breach to the relevant law
                    enforcement authorities and we will cooperate with those
                    authorities by disclosing your identity to them. In the
                    event of such a breach, your right to use our Site and App
                    will cease immediately.
                  </span>
                </li>
                <br></br>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Linking to our Site</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    You may link to our home page, provided you do so in a way
                    that is fair and legal and does not damage our reputation or
                    take advantage of it.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    You must not establish a link in such a way as to suggest
                    any form of association, approval or endorsement on our part
                    where none exists.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    You must not establish a link to our Site or App in any
                    website that is not owned by you.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    Our Site and App must not be framed on any other website,
                    nor may you create a link to any part of our Site or App
                    other than the home page.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    We reserve the right to withdraw linking permission without
                    notice.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    The website to which you are linking must comply in all
                    respects with any content standards we issue from time to
                    time.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    If you wish to make any use of content on our Site or App
                    other than that set out above, please contact us at{" "}
                    <a href="mailto: contact@aptap.co.uk">
                      contact@aptap.co.uk
                    </a>
                    .
                  </span>
                </li>
                <br></br>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Prohibited Uses</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    You may use our Service only for lawful purposes. You may
                    not use our Service:
                  </span>
                  <ol className={classes.ol}>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        In any way that breaches any applicable local, national
                        or international law or regulation.
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        In any way that is unlawful or fraudulent, or has any
                        unlawful or fraudulent purpose or effect.
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        For the purpose of harming or attempting to harm minors
                        in any way.
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        To send, knowingly receive, upload, download, use or
                        reuse any material which does not comply with any of our
                        content standards.
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        To transmit, or procure the sending of, any unsolicited
                        or unauthorised advertising or promotional material or
                        any other form of similar solicitation (spam).
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        To knowingly transmit any data, send or upload any
                        material that contains viruses, Trojan horses, worms,
                        time-bombs, keystroke loggers, spyware, adware or any
                        other harmful programs or similar computer code designed
                        to adversely affect the operation of any computer
                        software or hardware.
                      </span>
                    </li>
                    <br></br>
                  </ol>
                </li>
                <li className={classes.li}>
                  <span className={classes.span}>You also agree:</span>
                  <ol className={classes.ol}>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        Not to reproduce, duplicate, copy or resell any part of
                        our Site or App in contravention of these Terms.
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        Not to access without authority, interfere with, damage
                        or disrupt:
                      </span>
                      <ol className={classes.ol}>
                        <br></br>
                        <li className={classes.li}>
                          <span className={classes.span}>
                            any part of our Site or App;
                          </span>
                        </li>
                        <br></br>
                        <li className={classes.li}>
                          <span className={classes.span}>
                            any equipment or network on which our Site or App is
                            stored;
                          </span>
                        </li>
                        <br></br>
                        <li className={classes.li}>
                          <span className={classes.span}>
                            any software used in the provision of our Site or
                            App; or
                          </span>
                        </li>
                        <br></br>
                        <li className={classes.li}>
                          <span className={classes.span}>
                            any equipment or network or software owned or used
                            by any third party.
                          </span>
                        </li>
                        <br></br>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Suspension and Termination</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    You may close your account at any time by deleting your
                    account or by emailing{" "}
                    <a href="mailto: contact@aptap.co.uk">
                      contact@aptap.co.uk
                    </a>
                    . When confirmed, your account will be closed and you will
                    no longer be able to login. Your account data will be
                    removed in line with our Privacy Policy.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    We may at any time, terminate your access to the Service:
                  </span>
                  <ol className={classes.ol}>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        if you have breached any provision of the Terms (or have
                        acted in a manner which clearly shows that you do not
                        intend to, or are unable to comply with the provisions
                        of these Terms);
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        if we in our sole discretion believe we are required to
                        do so by law; and/or
                      </span>
                    </li>
                    <br></br>
                    <li className={classes.li}>
                      <span className={classes.span}>
                        by notice to the email address you provided when you
                        registered for the Service.
                      </span>
                    </li>
                    <br></br>
                  </ol>
                </li>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Miscellaneous</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    If any provision of these Terms is deemed unlawful, void, or
                    for any reason unenforceable, then that provision shall be
                    deemed severable from these Terms and shall not affect the
                    validity and enforceability of any remaining provisions.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    If we do not exercise or enforce any legal right or remedy
                    which is contained in these Terms (or which we have the
                    benefit of under any applicable law), this will not be taken
                    to be a formal waiver of our rights and that those rights or
                    remedies will still be available to us.
                  </span>
                </li>
                <br></br>
              </ol>
            </li>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Applicable Law</b>
                <br></br>
                <br></br>
                These Terms will be interpreted, construed and enforced in all
                respects in accordance with the laws of England and Wales, and
                the Courts of England and Wales will normally be where any legal
                proceedings should be brought, except that if you are a resident
                of Northern Ireland you may also bring proceedings in Northern
                Ireland, and if you are resident of Scotland, you may also bring
                proceedings in Scotland. Until the end of the UK&#39;s
                transition period from the EU, some disputes may be suitable for
                online resolution via the{" "}
                <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage">
                  European Commission Online Dispute Resolution
                </a>{" "}
                platform.
              </span>
            </li>
            <br></br>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Changes to these Terms</b>
                <br></br>
                <br></br>
                We may revise these Terms at any time by amending this page.
                Please check this page from time to time for any changes, as
                they are binding on you upon your first use of the Service after
                the changes have been introduced. In the event of any conflict
                between the current version of these Terms and any previous
                version(s), the provisions that are current and in effect will
                prevail (unless it is expressly stated otherwise). We will
                inform you within 2 months via email of any changes to these
                Terms.
              </span>
            </li>
            <br></br>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Complaints and Contact</b>
                <br></br>
                <br></br>
                To contact us or to complain, please email{" "}
                <a href="mailto: contact@aptap.co.uk">contact@aptap.co.uk</a>,
                and we will aim to resolve your issue as soon as possible. If
                your complaint is about how we store or process your personal
                information, you can also contact the Information Commissioner's
                Office, although we’d appreciate the chance to deal with your
                concern first.
                <br></br>
                <br></br>
                If your complaint relates to the provision of the account
                aggregation services offered on the ApTap platform, we will send
                you a final response within 15 business days of the receipt of
                your complaint. In exceptional circumstances, we will send you a
                holding reply specifying the deadline by which you will receive
                our response, being no later than 35 business days from the date
                of your initial complaint. If we have not responded or you are
                unhappy with our final response, you may able to refer the
                matter to the Financial Ombudsman Service (FOS). You can contact
                the FOS at The Financial Ombudsman Service, Exchange Tower,
                London, E14 9SR or by calling them on 0800 023 4567. Further
                information about this service is available from their website{" "}
                <a href="https://financial-ombudsman.org.uk">
                  www.financial-ombudsman.org.uk
                </a>
                . Referring your complaint to the FOS does not impact your right
                to take legal action.
                <br></br>
                <br></br>
                There are various ways in which alternative dispute resolution
                (ADR) can take place, including in person, by telephone, in
                writing or online. There is an online dispute resolution (ODR)
                platform created by the EU Commission which allows consumers to
                submit their complaint through a central site, which will
                forward the complaint to the right ADR scheme (FOS in the UK).
                For more information about ODR please visit{" "}
                <a href="https://ec.europa.eu/odr">https://ec.europa.eu/odr</a>.
                <br></br>
                <br></br>
                As stated, we may communicate with you via our website and via
                email, SMS or phone. Please understand that you will need
                internet access or phone service to receive these
                communications. All correspondence from ApTap will be sent in
                English. You have the right to receive a copy of these terms and
                conditions at your request under the Payment Services
                Regulations 2017, section 49.
              </span>
            </li>
            <br></br>

            <li className={classes.li}>
              <span className={classes.span}>
                <b>Switch Offer - Terms and Conditions</b>
              </span>
              <ol className={classes.ol}>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    This offer is open to UK residents, aged 18 or older that
                    are new or existing ApTap customers.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    This promotion offers customers who switch broadband
                    providers with ApTap one Amazon Voucher worth £50 (Fifty
                    pounds).
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    This is a free to enter offer for ApTap customers.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    Entries are limited to one per household, regardless of the
                    number of switches a customer completes, and repeat entries
                    will not be eligible.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    To be eligible for an Amazon Voucher, you must switch to a
                    new broadband provider via ApTap, and the switch must be
                    active and confirmed by the broadband provider. Cancelled
                    switches do not apply.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    Subject to eligibility and compliance with these terms and
                    conditions, the £50 Amazon Voucher will be emailed directly
                    into the email address entered in the switch personal
                    information page, between 30 and 90 days from the day that
                    the switch was processed on ApTap.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    The £50 Amazon Voucher is not transferable and there are no
                    alternatives available.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    ApTap reserves the right to amend, withdraw or restrict this
                    promotion at any time without notice.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    ApTap will void any entry in the event of suspected abuse or
                    fraud.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    ApTap accepts no liability or responsibility for claims
                    under this promotion which are lost, delayed or
                    underdelivered, nor any liability for technical errors or
                    communication failures in networks and/or internet access.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}>
                  <span className={classes.span}>
                    This promotion shall be governed and construed in accordance
                    with the laws of England and Wales.
                  </span>
                </li>
                <br></br>
                <li className={classes.li}><span className={classes.span}>
                The promoter of this offer is ApTap Limited (“ApTap”), company number 11171584, whose registered office is 124 City Road, London, England, EC1V 2NX.
                </span></li>
                <br></br>
              </ol>
            </li>
          </ol>
        </Typography>
      </div>
      <div className={classes.bottom}>
        <Footer />
      </div>
    </div>
  );
}

export default TandCs;
