import React, { useEffect } from 'react';
import { withRouter } from '../../helpers/withRouter';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import * as colors from "../../constants/colors";
import aptap_logo_white from '../../assets/images/aptap_logo_white_2023.svg';

import { MOBILE_SCHEMA } from "../../network/networkConstants";

const useStyles = makeStyles({
    page: {
        height: 'revert',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0,
        background: 'linear-gradient(120.55deg, #217DEC 6.73%, #705FEC 48.91%, #9756E9 92.14%)',
    },
    header: {
        color: colors.WHITE,
        fontFamily: 'Work Sans',
        fontWeight: 600,
        fontSize: 25,
        lineHeight: '29.33px',
        padding: '20px 30px 30px',
        textAlign: 'center',
    }
});

const NativeChangePasswordRedirect = () => {
    const classes = useStyles();

    useEffect(() => {
        const queryParsingFn = async () => {
            console.log("NativeChangePasswordRedirect window", window.location);
            if (window.location.href.indexOf(`${MOBILE_SCHEMA}`) <= -1) {
                console.log("NativeChangePasswordRedirect href", `${MOBILE_SCHEMA}/native-forget${window.location.search}`);
                window.location.href = `${MOBILE_SCHEMA}/native-forget${window.location.search}`;
            }
        }
        queryParsingFn();
    }, []);

    return (
        <div className={classes.page}>
            <img src={aptap_logo_white} style={{ width: 250 }} alt="aptap logo" />
            <Typography tabIndex={1} className={classes.header}>
                Please make sure you are using your mobile<br/ >
                device to reset your password
            </Typography>
        </div>
    );
}

export default withRouter(NativeChangePasswordRedirect);
