import ARTICLES_QUERY from "../../queries/articles/articles";
import Articles from "../../components/articles/Articles";
import Query from "../../components/query";

const Home = ({ setIsLoading }) => {
  return (
    <div>
      <Query query={ARTICLES_QUERY}>
        {({ data: { articles } }) => {
          setIsLoading(false);
          return <Articles articles={articles} />;
        }}
      </Query>
    </div>
  );
};

export default Home;
