import React from 'react';
import 'typeface-work-sans';

// Components
import Header from '../components/header/Header';
import MobileHeader from '../components/mobileHeader/MobileHeader';
import Footer from '../components/footer/Footer';
import SuppliersContent from './SuppliersContent';

// Material-UI
import Hidden from '@mui/material/Hidden';

import useStyles from './Suppliers-style';

function Suppliers() {
    const classes = useStyles();
    const contactRef = React.createRef();

    const scrollToContactForm = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className={classes.root}>
            <Hidden mdDown>
                <Header />
            </Hidden>
            <Hidden mdUp>
                <MobileHeader scrollToContactForm={scrollToContactForm}></MobileHeader>
            </Hidden>
            <SuppliersContent scrollToContactForm={scrollToContactForm} />
            <div ref={contactRef} className={classes.bottom}>
                <section id="footer">
                    <Footer />
                </section>
            </div>
        </div>
    );
}

export default Suppliers;
