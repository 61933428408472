const isSandbox = window.location.hostname.includes('.sandbox.aptapapp');

//export const BASE_URL = "https://api2.codeanddreams.com:5001/";
// export const BASE_URL = "https://apigateway.aptapapp.co.uk/";
// export const BASE_URL = "https://apigateway.aptapapp.co.uk/";
//export const BASE_URL = "http://apigateway.dev.aptapapi.internal/";
export const BASE_URL = isSandbox
    ? "https://apigateway.sandbox.aptapapp.co.uk/"
    : "https://apigateway.aptap.co.uk/";
export const API_KEY = "a843d11a-5cb8-49a6-bdd0-32a143f274a4";
export const MERCHANT_API_KEY = "cbd81334-faa8-4c58-a0e7-d07eb889945d";

export const IP_GEO_URL =
  "https://ipgeolocation.abstractapi.com/v1/?api_key=21b5ff1c3dcf4fc3b4a4894c9f45ecfe";
export const MOBILE_SCHEMA = isSandbox
    ? "aptapsandbox:/"
    : "aptap:/";

export const environment = isSandbox
    ? "aptap_dev"
    : "aptap_prod";