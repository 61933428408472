export const LOGIN_START = "LOGIN_START";
export const BANKCONNECT_INFO_CLICK = "BANKCONNECT_INFO_CLICK";
export const BANKCONNECT_TOBANK_CLICK = "BANKCONNECT_TOBANK_CLICK";
export const POSTCODESELECTION_CLICK = "POSTCODESELECTION_CLICK";
export const FORGETPASSWORD_CLICK = "FORGETPASSWORD_CLICK";
export const SIGNUP_CLICK = "SIGNUP_CLICK";
export const LOGIN_CLICK = "LOGIN_CLICK";
export const TODASHBOARD_DETAIL_CLICK = "TODASHBOARD_DETAIL_CLICK";
export const TO_PROFILE_DETAIL_CLICK = "TO_PROFILE_DETAIL_CLICK";
export const CHANGE_DEAL_STEP = "CHANGE_DEAL_STEP";
export const CHANGE_MODAL_POSITION = "CHANGE_MODAL_POSITION";
export const CHANGE_BILLING_START_DATE = "CHANGE_BILLING_START_DATE";
