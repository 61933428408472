import { makeStyles } from "@mui/styles";
import { aptapColors } from './design/materials/exports';

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: aptapColors.BILL_BLUE,
    },
    image: {
        width: 138,
    },
    header: {
        color: 'white',
        fontFamily: 'Work Sans',
        fontSize: 30,
        fontWeight: 700,
        lineHeight: 'normal',
        textAlign: 'center',
        margin: '20px 0 12px',
    },
    description: {
        color: 'white',
        fontFamily: 'Work Sans',
        fontSize: 16,
        lineHeight: 'normal',
        textAlign: 'center',
    },
    contactSupportText: {
        color: 'white',
        fontFamily: 'Work Sans',
        fontSize: 20,
        textDecoration: 'underline',
        marginBottom: 28,
    },
    backButton: {
        backgroundColor: 'white',
        width: '100%',
        maxWidth: 400,
        padding: '12px 0',
        borderRadius: 16,
        border: '1px solid rgba(70, 33, 142, 0.60)',
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.10)',
    },
    backText: {
        color: aptapColors.BILL_BLUE,
        fontFamily: 'DM Sans',
        fontSize: 20,
        fontWeight: 700,
    },
}));

export { useStyles };
