import { makeStyles } from '@mui/styles';

import { appCommonStyle } from '../../styles/appCommonStyles';

export const networkTypography = makeStyles(theme => ({
    HEADING_H1: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: 36,
        [theme.breakpoints.down('md')]: {
            fontSize: 18,
        }
    },
    HEADING_H2: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: 32,
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
        }
    },
    HEADING_H3: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: 26,
        [theme.breakpoints.down('md')]: {
            fontSize: 18,
        }
    },
    HEADING_H4: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: 24,
        [theme.breakpoints.down('md')]: {
            fontSize: 18,
        }
    },
    HEADING_H5: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: 22,
        [theme.breakpoints.down('md')]: {
            fontSize: 18,
        }
    },
    HEADING_H6: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: 20,
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
        }
    },
    SUBTITLE_1: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: 300,
        fontStyle: "normal",
        fontSize: 18,
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        }
    },
    SUBTITLE_2: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: 600,
        fontStyle: "normal",
        fontSize: 14,
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        }
    },
    SUBTITLE_3: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: 14,
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        }
    },
    BODY_1: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: 300,
        fontStyle: "normal",
        fontSize: 16
    },
    BODY_2: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: 12
    },
    //
    TEXT_BUTTON: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: "500",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "22.4px",
        letterSpacing: "0.05em"
    },
    TEXT_BUTTONBOLD: {
        fontFamily: appCommonStyle.fontFamily,
        fontWeight: "700",
        fontStyle: "normal",
        fontSize: "16px",
        lineHeight: "22.4px",
        letterSpacing: "0.05em"
    }
}));