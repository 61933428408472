import { makeStyles } from "@mui/styles";
import * as colors from '../../../../constants/colors';
import { aptapColors } from "../../../../design/materials/exports";

var path = window.location.pathname
var extensions = ["/banks", "/banks#servProv", "/blog", "/news", "/about-us", "/contact"];

const styles = makeStyles(theme => ({
  // main styles
  root: {
    background: 'white',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  appBar: {
    background: "white !important",
    boxShadow: "none",
    position: "relative",
    zIndex: "2",
    padding: "0px 80px",
  },
  toolBar: {
    padding: "0px",
  },
  menu: {
    width: '100%',
    height: 87,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 50
  },
  menuRightSection: {
    display: 'flex',
    marginLeft: 'auto',
  },

  // logo button styles
  aptapButton: {
    width: "150px",
    height: "75px",
  },
  aptapLogo: {
    width: "120px",
  },


  // menu stlyes
  pageButton: {
    marginLeft: "32px",
    color: extensions.includes(path) ? "#f1f1f1" : "#000e28",
    textTransform: 'unset !important',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      '& $imageMarked': {
        opacity: 1,
      }
    },
    [theme.breakpoints.down('lg')]: {
      marginLeft: "16px",
    },
  },
  imageMarked: {
    height: 2,
    width: '80%',
    backgroundColor: extensions.includes(path) ? "#f1f1f1" : "#586072",
    position: 'absolute',
    bottom: -2,
    transition: theme.transitions.create('opacity'),
    opacity: 0,
  },
  pageIndicator: {
    height: 2,
    width: '80%',
    backgroundColor: '#7744E2',
    position: 'absolute',
    bottom: -2,
  },
  menuText: {
    color: '#494D64',
    fontWeight: 500,
    fontSize: 20,
    fontFamily: 'Work Sans',
  },
  menuTextSelected: {
    color: '#7744E2',
    fontWeight: 500,
    fontSize: 20,
    fontFamily: 'Work Sans',
  },
  menuIcon: {
    color: "#000e28"
  },
  menuItemIcon: {
    color: "#000e28",
    marginRight: 18
  },
  menuItemIconSelected: {
    color: '#7744E2',
    marginRight: 18
  },
  menuIconSelected: {
    color: '#7744E2',
  },
  subMenuText: {
    color: "#000e28",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "16px",
    fontFamily: 'Work Sans'
  },
  subMenuTextSelected: {
    color: colors.APTAPPRIMARY,
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "16px",
    fontFamily: 'Work Sans'
  },


  // euro, login & signup styles
  buttonText: {
    color: "#FFFFFF",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "16px",
    fontFamily: 'Work Sans'
  },
  getTheAppButton: {
    fontWeight: 700,
    fontSize: 16,
    fontFamily: 'Work Sans',
    marginLeft: "16px",
    color: colors.WHITE,
    width: 145,
    height: 45,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
    textTransform: 'none',
    borderRadius: 52,
    backgroundColor: aptapColors.LIGHT_RED,
    '&:hover': {
      backgroundColor: aptapColors.LIGHT_RED,
      boxShadow: 'none'
    },
    [theme.breakpoints.down('lg')]: {
      // width: "100px",
      height: "36px"
    },
  },
}));

export default styles;
