import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({

  // background styles
  top: {
      background: 'linear-gradient(310deg, #d599c8 10%, #344177 80%)',
  },
  main: {
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottom: {
    marginTop: theme.spacing(-15),
    paddingTop: theme.spacing(15),
    background: '#ffffff'
  },

  // text styles
  title: {
    color: 'white',
    padding: theme.spacing(10, 0, 20),
  },
  subtitle: {
    color: '#344177',
    paddingBottom: '10px',
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },

  // grid styles
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    marginTop: theme.spacing(-15),
    padding: '50px 50px 30px 50px',
    width: '1260px',
    [theme.breakpoints.down('lg')]: {
      width: '940px',
      padding: '25px 25px 5px 25px',
    },
    [theme.breakpoints.down('md')]: {
      width: '600px',
    },
  },
  container: {
    display: 'flex',
    width: '100%',
    marginBottom: '30px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  leftSide: {
    float: 'left',
    width: '35%',
    paddingRight: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      float: 'none',
      paddingRight: '0px',
    },
  },
  rightSide: {
    width: '65%',
    paddingLeft: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      float: 'none',
      padding: '10px 0',
    },
  },
  img: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
      margin: '0 auto'
    },
  },
}));

export default styles;
