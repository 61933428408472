// Assets
import LoginPagePhones from "../../../assets/images/landing/LoginPagePhones.webp";

// Material-UI
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import useStyles from "./BannerStyles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";

function Banner({ handleModalOpen }) {
  const theme = useTheme();
  // const isMobile = window.innerWidth < 960;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ isMobile });

  return (
    <Grid
      container
      item
      direction={isMobile ? "column" : "row"}
      justifyContent="space-evenly"
      alignItems="center"
      wrap="nowrap"
      style={{ marginTop: isMobile ? 50 : null }}
    >
      <Grid
        container
        item
        direction={isMobile ? "column" : "row"}
        justifyContent="space-evenly"
        alignItems="center"
        style={{ padding: isMobile ? "0 15%" : "128px 15% 0" }}
      >
        <Grid
          container
          item
          xs={isMobile ? 12 : 6}
          direction="column"
          alignItems={isMobile ? "center" : ""}
          wrap="nowrap"
        >
          <Typography className={classes.headerText}>
            Helping TSB customers
            <br />
            save on their bills
          </Typography>
          <Button
            className={classes.getTheAppButtonPurple}
            onClick={() => handleModalOpen("Button 1")}
          >
            Get the app
          </Button>
        </Grid>
        {isMobile ? (
          <Grid item className={classes.bubbleContainer}>
            <img
              src={LoginPagePhones}
              className={classes.screens}
              alt="ApTap's Main Page"
            />
          </Grid>
        ) : (
          <Grid
            container
            item
            xs={6}
            justifyContent="space-evenly"
            alignItems="center"
            className={classes.bubbleContainer}
          >
            <img
              src={LoginPagePhones}
              className={classes.screens}
              alt="ApTap's Main Page"
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Banner;
