import React from 'react';

// Components
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import MobileHeader from '../components/mobileHeader/MobileHeader';

// Assets 
import news_img_01 from '../../../assets/images/landing_img/news_img_01.jpg';
import news_img_02 from '../../../assets/images/landing_img/news_img_02.jpg';
import news_img_03 from '../../../assets/images/landing_img/news_img_03.jpg';
import news_img_04 from '../../../assets/images/landing_img/news_img_04.jpg';
import news_img_05 from '../../../assets/images/landing_img/news_img_05.jpg';
import news_img_06 from '../../../assets/images/landing_img/news_img_06.jpg';
import news_img_07 from '../../../assets/images/landing_img/news_img_07.jpg';
import news_img_08 from '../../../assets/images/landing_img/news_img_08.jpg';
import news_img_09 from '../../../assets/images/landing_img/news_img_09.jpg';
import news_img_10 from '../../../assets/images/landing_img/news_img_10.jpg';
import news_img_11 from '../../../assets/images/landing_img/news_img_11.jpg';

// Material-UI
import useStyles from './News-style';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import Hidden from '@mui/material/Hidden';

function News() {
  const classes = useStyles();
  const contactRef = React.createRef();

  const scrollToContactForm = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const articles = [{
                      title: "Secret Leaders Podcast", 
                      blurb: `Secret Leaders is the UK's top business podcast featuring the stories & struggles to success from founders of companies likes Slack, Deliveroo, Calm, Shazam, Jo Malone, & more. It also features some of the investors crazy enough to back them, and group interviews from sold out live events on managing mental health, equality, financing, managing in times of crisis and more. Check out our other show "Represented" for black startup founders pitching for funding from great investors.`, 
                      image: news_img_04, 
                      link: "https://podcasts.apple.com/gb/podcast/represented-episode-3-pitch-for-aptap-from-nadal-sarkytbayev/id1229438369?i=1000485405980"
                    },
                    {
                      title: "ApTap and TSB Launch Bill Management Pilot",
                      blurb: `Today ApTap announced it has launched a pilot with TSB for its bill management service as the Bank looks to provide more solutions for customers to help them manage their money with confidence. The pilot will allow TSB’s employees to test the solution before it’s made available to customers.`,
                      image: news_img_02,
                      link: "https://startupdaily.pro/aptap-and-tsb-launch-bill-management-pilot-1110.html"
                    },
                    {
                      title: "ApTap and TSB Launch Bill Management Pilot",
                      blurb: `Today ApTap announced it has launched a pilot with TSB for its bill management service as the Bank looks to provide more solutions for customers to help them manage their money with confidence. The pilot will allow TSB’s employees to test the solution before it’s made available to customers.`,
                      image: news_img_06,
                      link: "https://thefintechtimes.com/aptap-and-tsb-launch-bill-management-pilot/"
                    },
                    {
                      title: "ApTap and TSB Launch Bill Management Pilot",
                      blurb: `ApTap has launched a pilot with TSB for its bill management service as the bank looks to provide more solutions for customers to help them manage their money.`,
                      image: news_img_09,
                      link: "https://thepaypers.com/e-invoicing-supply-chain-finance/tsb-aptap-roll-out-bill-management-pilot--1243437"
                    },
                    {
                      title: "ApTap and TSB Launch Bill Management Pilot",
                      blurb: `TSB Bank is launching a pilot for a bill management and utility switching service in partnership with white-label fintech provider ApTap.`,
                      image: news_img_08,
                      link: "https://www.finextra.com/newsarticle/36174/tsb-launches-bill-management-pilot"
                    },
                    {
                      title: "March 14th Full Episode on Bloomberg Television",
                      blurb: `Hosts Vince Molinari and Patricia Wu interview Perianne Boring, Lewis Cohen, Sam Englebardt.  Also from London, Correspondent Georgia Fairweather interviews Will Billingsley from London Blockchain Week.`,
                      image: news_img_07,
                      link: "https://fintech.tv/march-14th-full-episode-on-bloomberg-television/"
                    },
                    {
                      title: "2020 Startups and Scaleups",
                      blurb: `ApTap is a next-generation of affiliate marketplaces, housing end-to-end user journeys that allow users to cancel, compare, switch or sign up to services within their banking app. ApTap builds white-labelled financial management API's that allow banks to offer a simple bill management tools to their customers and generate affiliate revenue from switching.`,
                      image: news_img_10,
                      link: "https://tmt.knect365.com/elevating-founders/sponsors/aptap/"
                    },
                    {
                      title: "FIL Digital Graduation Day Experience",
                      blurb: `ApTap is a personalised bill and subscription management tool, that allows banking customers to cancel, compare, switch and sign up to services like energy, broadband and phone, all in-app, with just a few taps.`,
                      image: news_img_11,
                      link: "https://fintech-innovation-lab-london.relayto.com/e/fil-digital-graduation-day-experience-mvww9qbdxaim0/rjvLQ1PN19"
                    },
                    {
                      title: "ApTap: One Tap To Freedom",
                      blurb: ` The business doesn't want to stop getting your money, so the process of leaving is made so complex and convoluted that it takes a long time, and sometimes even tries to trick you into believing you have cancelled the subscription, when you in fact haven't.
                      So what if you could do it with just one click, from your existing mobile banking app, with no fuss at all? We spoke to the founders of ApTap, Will Billingsley (CFO), Nadal Sarkytbayev (CEO) and Isa Ibrahim (CTO), to find out how they're doing exactly that.`,
                      image: news_img_01,
                      link: "https://startupsmagazine.co.uk/article-aptap-one-tap-freedom"
                    },
                    {
                      title: "Imperial Enterprise Lab",
                      blurb: `Our mission is to bring simplicity and control to managing bills and subscriptions with just a tap and we want to build the world’s first Appstore for Subscriptions or an iTunes for Services. We believe this is something that could make a huge impact not only in the UK market, but also across the world.`,
                      image: news_img_03,
                      link: "https://www.imperialenterpriselab.com/casestudies/aptap/"
                    },
                    {
                      title: "Insight: The Evolution of Open Data",
                      blurb: `Open Banking Expo Magazine caught up with ApTap Co-Founder, Will Billingsley and here’s what he had to say on the evolution of Open Data…`,
                      image: news_img_05,
                      link: "https://www.openbankingexpo.com/insights/insight-the-evolution-of-open-data/"
                    }
                  ]

  return (
    <div>
      <div className={classes.top}>
        <Hidden mdDown>
          <Header />
        </Hidden>
        <Hidden mdUp>
          <MobileHeader scrollToContactForm={scrollToContactForm} />
        </Hidden>
        <Typography variant="h2" align="center" className={classes.title}>
          News
        </Typography>
      </div>
      <div className={classes.main}>
        <Card className={classes.card}>
            {articles.map((article) => (
              <div className={classes.container}>
                <div className={classes.leftSide}>
                  <Link className={classes.link} href={article.link}>
                    <img src={article.image} className={classes.img} alt={`${article.image}`} />
                  </Link>
                </div>
                <div className={classes.rightSide}>
                  <Link className={classes.link} href={article.link}>
                    <Typography variant="h3" className={classes.subtitle}>
                      {article.title}
                    </Typography>
                  </Link>
                  <Typography variant="body1">
                    {article.blurb}
                  </Typography>
                </div>
              </div>
            ))}
        </Card>
      </div>
      <div ref={contactRef} className={classes.bottom}>
        <section id="footer">
          <Footer></Footer>
        </section>
      </div>
    </div>
  );
}

export default News;
 