import { makeStyles } from "@mui/styles";
import * as colors from '../../../../constants/colors';
import { aptapColors } from "../../../../design/materials/exports";

const styles = makeStyles(theme => ({
  // main styles
  root: {
    backgroundColor: aptapColors.BACKGROUND_BLUE,
    maxWidth: 1920,
    margin: '0 auto',
    paddingBottom: 128,
    [theme.breakpoints.down('md')]: {
      padding: "32px 16px",
    }
  },
  main: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center"
  },
  testimonialContainer: {
    padding: "32px 200px 0px",
    margin: "0 auto",
    overflowX: "hidden",
    [theme.breakpoints.down('md')]: {
        padding: 0,
        width: "100%"
    },
  },

  testimonialContainerItem: {
    margin: '0 16px',
    [theme.breakpoints.down('md')]: {
      margin: '8px 0'
    }
  },

  testimonialIcon: {
    color: colors.APTAPPRIMARY,
    fontSize: 84,
    [theme.breakpoints.down('md')]: {
      fontSize: 64
    }
  },

  // typography styles
  subHeader1Text: {
    color: "#586072",
    fontFamily: 'Work Sans',
    fontWeight: 700,
    fontSize: 48,
    lineHeight: "56px",
    margin: "0 auto",
    [theme.breakpoints.down('md')]: {
      fontSize: "24px",
      lineHeight: "24px",
    }
  },
  bodyText: {
    color: '#586072',
    fontFamily: 'Work Sans',
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: 'center',
    paddingTop: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      lineHeight: "22px",
      paddingTop: 0
    }
  },
  testimonialName: {
    color: '#586072',
    fontSize: 36,
    fontWeight: 700,
    fontFamily: 'Work Sans',
    paddingTop: 24,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
      fontSize: 20
    }
  }
}));

export default styles;



