import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import deeperEngagementIcon from '../../../../assets/images/landing_img/engagement_icon.png';
import generateRevenueIcon from '../../../../assets/images/landing_img/revenue_icon.png';
import increaseRetentionIcon from '../../../../assets/images/landing_img/retention_icon.png';
import LandingBroadbandCardsBubble from '../../../../assets/images/landing/LandingBroadbandCardsBubble.webp';
import TSBAppBubble from '../../../../assets/images/landing/TSBAppBubble.webp';
import TSBLogo from '../../../../assets/images/logos/tsb_logo.png';
import TrueLayerLogo from '../../../../assets/images/logos/truelayer_logo.png';
import AwinLogo from '../../../../assets/images/logos/awin_logo.png';
import NetworkLogo from '../../../../assets/images/logos/network_logo.png';
import AccentureLogo from '../../../../assets/images/logos/accenture_logo.png';
import InnovateUKLogo from '../../../../assets/images/logos/innovateuk_logo.png';

import useStyles from './BankContent-style';
import { useMediaQuery, useTheme } from '@mui/material';
import * as colors from '../../../../constants/colors';

function BankContent() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container className={classes.bankContentContainer}>
            <Grid container direction={isMobile ? 'column' : 'row'} justifyContent="space-between" wrap="nowrap" align="center" className={classes.blockContainer}>
                <Grid container item direction="column" className={classes.blockItem}>
                    <Grid item><img src={deeperEngagementIcon} className={classes.blockIcon} alt="" /></Grid>
                    <Grid item><Typography className={classes.blockTitle}>Deeper Engagement</Typography></Grid>
                    <Grid item><Typography className={classes.blockText}>Increase digital engagement</Typography></Grid>
                </Grid>
                <Grid container item direction="column" className={classes.blockItem}>
                    <Grid item><img src={generateRevenueIcon} className={classes.blockIcon} alt="" /></Grid>
                    <Grid item><Typography className={classes.blockTitle}>Generate Revenue</Typography></Grid>
                    <Grid item><Typography className={classes.blockText}>Access to afilliate revenue</Typography></Grid>
                </Grid>
                <Grid container item direction="column" className={classes.blockItem}>
                    <Grid item><img src={increaseRetentionIcon} className={classes.blockIcon} alt="" /></Grid>
                    <Grid item><Typography className={classes.blockTitle}>Increase Retention</Typography></Grid>
                    <Grid item><Typography className={classes.blockText}>Empower customers with tools</Typography></Grid>
                </Grid>
            </Grid>
            <Grid container item alignItems="center" className={classes.blockContainer}>
                {!isMobile && <Grid container item xs={5} justifyContent="center"><img src={LandingBroadbandCardsBubble} alt="ApTap's deal comparison feature" style={{ maxWidth: isMobile ? 342 : 526 }} /></Grid>}
                <Grid container item xs={isMobile ? 12 : 7} direction="column">
                    <Typography className={classes.header} style={{ color: colors.CHEAPESTCARDCOLOR }}>Top class customer experience</Typography>
                    <Typography className={classes.bodyText} style={{ marginBottom: 32 }}>Our algorithms identify your user’s bills and subscriptions through open banking. We combine this with their service usage data to get bespoke and accurate savings information, instantly.</Typography>
                    <Typography className={classes.bodyText}>These customer profiles help you get even closer to customers.</Typography>
                </Grid>
                {isMobile &&
                    <Grid container item xs={12} justifyContent="center">
                        <img src={LandingBroadbandCardsBubble} alt="ApTap's deal comparison feature" 
                            style={{ maxWidth: isMobile ? 342 : 526, paddingBottom: 32, paddingTop: isMobile ? 30 : '' }}
                        />
                    </Grid>
                }
            </Grid>
            <Grid container item direction="column" alignItems="center" className={classes.blockContainer}>
                <Grid container item justifyContent="center"><Typography className={classes.header} style={{ color: '#7744E2' }}>Integration methods that suit your needs</Typography></Grid>
                <Grid item><Typography className={classes.bodyText}>From simple APIs to white labelled applications, we offer a full range of options to meet individual requirements. Rest assured the data is secure at every stage with bank grade encryption on a GDPR compliant platform.</Typography></Grid>
            </Grid>
            <Grid container item alignItems="center" className={classes.blockContainer}>
                <Grid container item xs={isMobile ? 12 : 7} direction="column">
                    <Typography className={classes.header} style={{ color: '#CB6CE6' }}>Our partners</Typography>
                    <Typography className={classes.bodyText} style={{ marginBottom: 32 }}>TSB Bank has partnered with ApTap to help deliver money confidence to their five million customers.</Typography>
                    <Typography className={classes.bodyText}>Pella Frost, Director, Everyday Banking, TSB, says: “Our partnership with ApTap gives our customers confidence to make informed choices about how best to spend their money. In just a few clicks they can compare and save on their regular monthly bills, helping to make their money go further.”</Typography>
                </Grid>
                <Grid container item xs={isMobile ? 12 : 5} justifyContent="center"><img src={TSBAppBubble} alt="ApTap's app as used from TSB" style={{ paddingTop: isMobile ? 24 : null }} /></Grid>
            </Grid>
            <Grid container item direction="column" alignItems="center" className={classes.blockContainer}>
                <Grid container item justifyContent="center"><Typography className={classes.header} style={{ color: '#586072' }}>Trusted by</Typography></Grid>
                <Grid container item alignItems="center" justifyContent="center" spacing={4} style={{ padding: isMobile ? '20px 0 40px' : '0 140px', margin: isMobile ? null : '0 -32px' }}>
                    <img src={TSBLogo} alt="TSB's logo" style={{ width: isMobile ? 101 : 164, margin: isMobile ? '0 16px' : '0 32px' }} />
                    <img src={TrueLayerLogo} alt="TrueLayer's logo" style={{ width: isMobile ? 138 : 326, margin: isMobile ? '0 16px' : '0 32px' }} />
                    <img src={AwinLogo} alt="Awin's logo" style={{ width: isMobile ? 106 : 180, margin: isMobile ? '0 16px' : '24px 32px 0' }} />
                    <img src={InnovateUKLogo} alt="Innovate UK's logo" style={{ width: isMobile ? 140 : 250, margin: isMobile ? '0 16px' : '0 32px' }} />
                    <img src={NetworkLogo} alt="Network's logo" style={{ width: isMobile ? 220 : 550, margin: isMobile ? '0 16px' : '0 32px' }} />
                    <img src={AccentureLogo} alt="Accenture's logo" style={{ width: isMobile ? 82 : 202, margin: isMobile ? '0 16px' : '0 32px' }} />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BankContent;