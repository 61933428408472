import { makeStyles } from "@mui/styles";
import * as colors from "../../../constants/colors";
import { aptapColors } from "../../../design/materials/exports";

const styles = makeStyles(theme => ({
  // main styles
  root: {
    marginTop: 128,
    maxWidth: 1920,
    margin: '0 auto',
    padding: "0 200px 0",
    backgroundColor: aptapColors.BACKGROUND_BLUE,
    [theme.breakpoints.down('md')]: {
      padding: "0 16px",
      paddingTop: 24,
      marginTop: 0,
    },
  },
  lhs: {
    marginTop: "0px",
    height: "600px",
    float: "left",
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down('md')]: {
      float: "initial",
      width: "100%",
      marginTop: "0px",
      clear: "left",
    },
  },
  rhs: {
    marginTop: "0px",
    height: "600px",
    float: "right",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingRight: "120px",
    paddingLeft: "120px",
    [theme.breakpoints.down('md')]: {
      float: "initial",
      width: "100%",
      paddingRight: "10px",
      paddingLeft: "10px",
      marginTop: "0px",
    },

  },
  lhs2: {
    marginTop: "100px",
    height: "600px",
    float: "left",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: "120px",
    paddingRight: "120px",
    [theme.breakpoints.down('md')]: {
        float: "initial",
        width: "100%",
        paddingRight: "10px",
        paddingLeft: "10px",
        marginTop: "0px",
    },
  },
  rhs2: {
    marginTop: "100px",
    height: "600px",
    float: "right",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      float: "initial",
      width: "100%",
      marginTop: "0px",
    },
  },

  // typography styles
  subHeader1Text: {
    color: "#0E9AF1",
    fontFamily: 'Work Sans',
    fontWeight: "700",
    fontSize: "48px",
    lineHeight: "56px",
    [theme.breakpoints.down('md')]: {
      fontSize: "24px",
      lineHeight: "24px",
      textAlign: "center",
      paddingTop: "24px"
    },
  },
  bodyText: {
    color: "#586072",
    fontFamily: 'Work Sans',
    fontWeight: 400,
    fontSize: 24,
    lineHeight: "28px",
    padding: "32px 0",
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      lineHeight: "18px",
      padding: "24px 0",
    }
  },

  // button styles
  button: {
    color: 'white',
    width: 228,
    height: 40,
    textTransform: "none",
    borderRadius: 16,
    boxShadow: '0px 0px 2px rgba(0, 14, 40, 0.04), 0px 2px 4px rgba(92, 100, 116, 0.16)',
    '&:hover': {
      boxShadow: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: "140px",
      height: "32px",
      margin: '0 auto 24px',
    },
  },
  buttonText: {
    color: "white",
    fontFamily: 'Work Sans',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "14px",
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  getTheAppButtonBlue: {
    fontFamily: 'Work Sans',
    fontWeight: 700,
    fontSize: 20,
    marginTop: 30,
    width: 214,
    height: 54,
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.25)',
    textTransform: "unset !important",
    borderRadius: 52,
    background: colors.CHEAPESTCARDCOLOR,
    color: 'white',
    '&:hover': {
      background: colors.CHEAPESTCARDCOLOR,
      boxShadow: 'none'
    },
  },
  getTheAppButtonPurple: {
    fontFamily: 'Work Sans',
    fontWeight: 700,
    fontSize: 20,
    marginTop: 30,
    width: 214,
    height: 54,
    boxShadow: '0px 3px 15px rgba(0, 0, 0, 0.25)',
    textTransform: "unset !important",
    borderRadius: 52,
    background: '#8951E7',
    color: 'white',
    '&:hover': {
      background: "#8951E7",
      boxShadow: 'none'
    },
  },

  // image styles
  image: {
    width: 600,
    [theme.breakpoints.down('md')]: {
      width: 305,
    }
  },
  bubbleContainer: {
    backgroundColor: colors.WHITE,
    borderRadius: '50%',
    aspectRatio: '1',
    overflow: 'visible',
  },
  //

  featureTitle: {
    color: "#CB6CE6",
    fontFamily: 'Work Sans',
    fontWeight: 700,
    fontSize: 48,
    lineHeight: "56px",
    margin: "0 auto",
    textAlign: 'center',
    marginBottom: 32,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      lineHeight: "24px",
      marginBottom: 24,
      padding: '32px 16px 0'
    }
  },
  
  blockContainer: {
    marginBottom: 128,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: "center",
      padding: '0 16px',
      margin: "0 auto",
      marginBottom: 24
    }
  },
  blockItem: {
    maxWidth: 485,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 24
    }
  },
  blockIcon: {
    width: 156,
    height: 156,
    [theme.breakpoints.down('md')]: {
      width: 103,
      height: 103
    }
  },
  blockTitle: {
    color: '#586072',
    fontSize: 36,
    fontWeight: 500,
    fontFamily: 'Work Sans',
    padding: '16px 0 8px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 24
    }
  },
  blockText: {
    color: '#586072',
    fontSize: 24,
    fontWeight: 400,
    fontFamily: 'Work Sans',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    }
  }
}));

export default styles;



