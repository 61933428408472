import { withStyles } from "@mui/styles";
import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';

import * as colors from '../../../constants/colors';

const useStyles = theme => ({
    form: {
        width: '100%',
        maxWidth: 484,
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
    },
    formTitle: {
        fontSize: 48,
        fontWeight: 700,
        fontFamily: 'Work Sans',
        lineHeight: '48px',
        textAlign: 'center',
        marginBottom: 24,
        [theme.breakpoints.down('md')]: {
            fontSize: 24
        }
    },
    formLabel: {
        fontSize: 16,
        fontFamily: 'Work Sans',
        marginBottom: 8
    },
    reasonSelect: {
        display: 'flex',
        alignItems: 'center'
    },
    submitButton: {
        width: 256,
        height: 40,
        backgroundColor: 'white',
        color: '#586072',
        fontSize: 18,
        fontFamily: 'Work Sans',
        fontWeight: 500,
        borderRadius: 16,
        textTransform: 'none',
        '&:hover': {
            background: 'white',
        },
    },
});

const BootstrapInput = withStyles((theme) => ({
    root: {
        marginBottom: 24,
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        height: 39,
        borderRadius: 16,
        position: 'relative',
        backgroundColor: colors.WHITE,
        padding: '5px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        color: colors.BLACK,
        fontFamily: 'Work Sans',
        fontWeight: "400",
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "16.8px",
        '&:focus': {
            backgroundColor: colors.WHITE,
            borderRadius: 16,
            color: colors.BLACK,
            fontFamily: 'Work Sans',
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "16.8px",
        },
    },
}))(InputBase);

const BootstrapSelectInput = withStyles((theme) => ({
    root: {
        marginBottom: 24,
        'label + &': {
            marginTop: theme.spacing(3),
        },
        '& .MuiSelect-icon': {
            color: '#000E28'
        }
    },
    input: {
        height: 39,
        borderRadius: 16,
        position: 'relative',
        backgroundColor: colors.WHITE,
        padding: '5px 26px 5px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        color: colors.BLACK,
        fontFamily: 'Work Sans',
        fontWeight: "400",
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "16.8px",
        '&:focus': {
            backgroundColor: colors.WHITE,
            borderRadius: 16,
            color: colors.BLACK,
            fontFamily: 'Work Sans',
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "14px",
            lineHeight: "16.8px",
        }
    }
}))(InputBase);

const CustomTextField = withStyles((theme) => ({
    root: {
        padding: '5px 12px',
        marginBottom: 24,
        backgroundColor: colors.WHITE,
        borderRadius: 16,
        '& .MuiOutlinedInput-root': {
            backgroundColor: colors.WHITE,
            borderRadius: 16,
            '& textarea': {
                color: '#000E28'
            },
            '& fieldset': {
                border: 'none',
                borderRadius: 16,
            },
            '&:hover fieldset': {
                border: 'none',
                borderRadius: 16,
            },
            '&.Mui-focused fieldset': {
                border: 'none',
                borderRadius: 16,
            },
        },
    }
}))(TextField);

export { useStyles, BootstrapInput, BootstrapSelectInput, CustomTextField };