const mixpanelProjectTokens = [
    { name: 'barclays', token: '0a0dd49582f4d7ac6688c02497f5c00b' },
    { name: 'creation', token: '269b981031e9d5ef4f7bb18d236f957e' },
    { name: 'h', token: 'caa4a1a3af87deb70793461fe52e5571' },
    { name: 'l', token: 'bb0d72f1e2a5f7150e290f9130586e08' },
    { name: 'sprive', token: '552b55e0910274a973fc7b310853171f' },
    { name: 'tsb', token: '82270cda9a9e23b7c2b8e22fa1fb05b3' },
];

export default mixpanelProjectTokens;
