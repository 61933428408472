import React from 'react';
import emailjs from 'emailjs-com';
import apiKeys from './MailJsApiKeys';
import 'typeface-work-sans';

import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { useStyles, BootstrapInput, BootstrapSelectInput, CustomTextField } from './ContactBottom-style';

class ContactBottom extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            consent: false,
            success: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.resetForm = this.resetForm.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        const { name, value, type, checked } = event.target
        type === "checkbox"
            ? this.setState({
                [name]: checked
            })
            : this.setState({
                [name]: value
            })
    }

    resetForm() {
        this.setState({ name: '', email: '', subject: '', message: '', consent: false, success: true })
    }

    handleSubmit(e) {
        e.preventDefault();
        emailjs.sendForm(apiKeys.SERVICE_ID, apiKeys.TEMPLATE_ID, e.target, apiKeys.USER_ID)
            .then((result) => {
                console.log("Email response: ", result.text);
                this.resetForm();
            }, (error) => {
                console.log("Email response: ", error.text);
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                <Grid container item direction="column" justifyContent='center' alignItems="stretch" wrap="nowrap">
                    <Typography className={classes.formTitle}>Contact Us</Typography>
                    <Typography tabIndex={0} align="left" className={classes.formLabel}>Full Name</Typography>
                    <BootstrapInput name="name" id="name" autoComplete="off" value={this.state.name} onChange={this.handleChange} />
                    <Typography tabIndex={0} align="left" className={classes.formLabel}>Email Address</Typography>
                    <BootstrapInput name="email" id="email" autoComplete="off" value={this.state.email} onChange={this.handleChange} />
                    <Typography tabIndex={0} align="left" className={classes.formLabel}>Reason for getting in touch</Typography>
                    <Select
                        name="subject"
                        id="subject"
                        value={this.state.subject}
                        onChange={this.handleChange}
                        input={<BootstrapSelectInput />}
                        IconComponent={() => null}
                        classes={{ root: classes.reasonSelect }}
                        required
                        fullWidth
                    >
                        <MenuItem value="" disabled>Please select</MenuItem>
                        <MenuItem value="Customer Support">Customer Support</MenuItem>
                        <MenuItem value="Technical Demo">Technical Demo</MenuItem>
                        <MenuItem value="Integration Query">Integration Query</MenuItem>
                        <MenuItem value="Join Supplier Networ">Join Supplier Network</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                    <Typography tabIndex={0} align="left" className={classes.formLabel}>Type your message here</Typography>
                    <CustomTextField
                        name="message"
                        id="message"
                        multiline={true}
                        minRows={5}
                        autoComplete="off"
                        value={this.state.message}
                        onChange={this.handleChange}
                        required
                        fullWidth
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type='submit' className={classes.submitButton}>
                            Submit
                        </Button>
                    </div>
                    { this.state.success ? 
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px'}}>
                            <Typography className={classes.formLabel}>
                                Your message has been sent successfully
                            </Typography>
                        </div>
                    : 
                        <></>
                    }
              
                </Grid>
            </form>
        )
    }
}

export default withStyles(useStyles)(ContactBottom);
