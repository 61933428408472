import React, { useEffect } from 'react';
import { Grid, Modal, Typography } from '@mui/material';
import { getMobileOperatingSystem } from '../../../helpers/getMobileOperatingSystem';

import { useStyles } from "./QrCodeModalStyles";

import AppStore from '../../../assets/images/landing/AppStore.svg';
import GooglePlay from '../../../assets/images/landing/GooglePlay.png';

import QrCodeRedirect from '../home/QrCodeRedirect';
import QrCodeApTap from '../../../assets/images/landing/QrCodeApTap.webp';
import QrCodeTsb from '../../../assets/images/landing/QrCodeTsb.webp';

var mixpanel = require('mixpanel-browser');

const QrCodeModalMobile = ({ isMobile, currentPage, modalOpen, handleModalClose }) => {
  const classes = useStyles({ isMobile, currentPage });

  const deviceUsed = getMobileOperatingSystem();

  useEffect(() => {
    if (modalOpen) {
      let displayedButton;

      switch (deviceUsed) {
        case 'Android':
          displayedButton = 'Google Play Store';
          break;
        case 'iOS':
          displayedButton = 'Apple App Store';
          break;
        default:
          displayedButton = 'QR Code';
          break;
      }

      mixpanel.track("Button Displayed", { "Displayed button": displayedButton, "Landing page": currentPage });
    }
  }, [modalOpen, deviceUsed, currentPage]);

  const storeClick = (store) => {
    let buttonClicked, storeUrl;

    switch (store) {
      case 'Android':
        buttonClicked = 'Google Play Store';
        storeUrl = currentPage === 'TSB'
          ? 'https://play.google.com/store/apps/details?id=com.aptap.mobile&referrer=utm_source%3DTSB%26utm_medium%3DTSB_in-app_refer'
          : 'https://play.google.com/store/apps/details?id=com.aptap.mobile';
        break;
      case 'iOS':
        buttonClicked = 'Apple App Store';
        storeUrl = currentPage === 'TSB'
          ? 'https://apps.apple.com/app/apple-store/id1596457853?pt=119145408&ct=TSB_in-app_refer&mt=8'
          : 'https://apps.apple.com/app/aptap/id1596457853';
        break;
      default:
        break;
    }

    mixpanel.track("App Store Click", { "Clicked button": buttonClicked, "Landing page": currentPage });
    window.location.href = storeUrl;
  };

  if (currentPage === 'Network') {
    return (
      <QrCodeRedirect />
    );
  }

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby='Download ApTap now!'
      aria-describedby='Download ApTap now!'
    >
      <Grid container item justifyContent='space-evenly' alignItems='center' className={classes.qrModalContainer}>
        <Grid item>
          <Typography className={classes.modalHeader}>Download ApTap now!</Typography>
          <Typography className={classes.modalContent}>Download ApTap for free today and let us help you manage your finances.</Typography>
        </Grid>
        <Grid container item justifyContent='space-evenly' alignItems='center'>
          { deviceUsed === 'Android' &&
            <img src={GooglePlay} alt="The Google Play icon" onClick={() => storeClick('Android')} />
          }
          { deviceUsed === 'iOS' &&
            <img src={AppStore} alt="The App Store icon" onClick={() => storeClick('iOS')} />
          }
          { deviceUsed === 'unknown' && <img src={currentPage === 'TSB' ? QrCodeTsb : QrCodeApTap} className={classes.qrCode} alt="A QR code to download ApTap" /> }
        </Grid>
      </Grid>
    </Modal>
  );
};

export default QrCodeModalMobile;
