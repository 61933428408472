import React from "react";

// Material-UI
import useStyles from "./FAQsStyles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMediaQuery, useTheme } from "@mui/material";

const FAQs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles({ isMobile });

  const lhsQuestions = [
    {
      q: "How does ApTap work?",
      a:
        "The ApTap algorithms identify bills in your bank accounts so that ApTap can offer comparison and switching. ApTap works with TrueLayer, to offer open banking capabilities, and EnergyHelpline, for utilities comparison and switching.",
    },
    {
      q: "How does the TSB partnership with ApTap work?",
      a:
        "This is a referral relationship. TSB directs customers that want to use bill management tools to ApTap. Once you land on this page, your customer relationship will be with ApTap. ApTap will only share anonymised data with TSB so that the bank can measure the effectiveness and performance of the relationship. TSB won’t be able to see any personal details shared with ApTap. ApTap will also use this data to further develop their proposition.",
    },
    {
      q: "How is my data protected?",
      a:
        "At ApTap, we use the same state of the art encryption technology as banks, so that your data is always protected. We work closely with our suppliers to ensure your data is secure at every step, and only work with select third parties that have outstanding track records, such as TrueLayer (FCA reference number: 850828) and EnergyHelpline.",
    },
    {
      q: "Can ApTap move my money?",
      a:
        "No. ApTap will have read-only access to your bank account via Open Banking. They will not be able to make withdrawals, payments or transfer money. Whenever you switch service provider, the new service provider will take care of setting up your Direct Debit.",
    },
    {
      q: "What’s the best way to contact ApTap?",
      a:
        "Use the support button in the corner of your screen to speak with us directly or send us an email at contact@aptap.co.uk. Have any data queries? Use data@aptap.co.uk",
    },
  ];
  const rhsQuestions = [
    // {q: "Will ApTap delete my data when I ask you to?", a: `Of course. Your data and its security is extremely important to us. Therefore, the subscription management tools are built with GDPR, Data Privacy and Open Banking at the core. ApTap will delete any identifiable information about you upon your request unless we are obligated by law to retain it. EnergyHelpline and TrueLayer will also be notified to do the same in line with their legal and regulatory obligations. Please see the` <a href="/privacy-policy"> privacy policy </a> `for more details.`},
    {
      q: "Can I link a joint bank account to the app?",
      a:
        "You can connect a joint bank account, but you’ll need to get permission from the other account holder before making a switch.",
    },
    {
      q: "Can I switch a bill that’s in someone else’s name?",
      a:
        "In most cases, you will not be able to switch if you are not named on the provider account. Please do not switch unless you have the authority to do so. Before confirming the switch we will ask whether you have the authority to switch but ultimately it is your responsibility.",
    },
    {
      q: "Is there anything ApTap can’t do?",
      a:
        "Unfortunately, you won’t be able to cancel your subscriptions or services within the app. You will need to speak to the service provider directly if you wish to cancel. This is a feature we are working on!",
    },
    {
      q: "Is the app free?",
      a:
        "You will be able to use the app for free. ApTap will make a commission every time someone switches or signs up to a new service provider through the app. TSB Bank will take a share of that commission if you switch. Deals will be presented to you based on the best match not what makes us the most commission.",
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.faqContainer}>
        <div className={classes.main}>
          <Typography className={classes.subHeader1Text}>
            Frequently asked questions
          </Typography>
        </div>

        <Container className={classes.questionContainer} maxWidth={false}>
          <Grid container>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              className={classes.questions}
              style={{ paddingRight: isMobile ? null : 32 }}
            >
              {lhsQuestions.map((lhsQuestion, lhsIndex) => (
                <Accordion className={classes.accordion} key={lhsIndex}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.bodyText}>
                      {lhsQuestion.q}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.bodyText2}>
                      {lhsQuestion.a}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
            <Grid item md={6} sm={12} xs={12} className={classes.questions}>
              <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.bodyText}>
                    Will ApTap delete my data when I ask you to?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.bodyText2}>
                    Of course. Your data and its security is extremely important
                    to us. Therefore, the subscription management tools are
                    built with GDPR, Data Privacy and Open Banking at the core.
                    ApTap will delete any identifiable information about you
                    upon your request unless we are obligated by law to retain
                    it. EnergyHelpline and TrueLayer will also be notified to do
                    the same in line with their legal and regulatory
                    obligations. Please see the{" "}
                    <a href="/privacy-policy">privacy policy</a> for more
                    details.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              {rhsQuestions.map((rhsQuestion, rhsIndex) => (
                <Accordion className={classes.accordion} key={rhsIndex}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.bodyText}>
                      {rhsQuestion.q}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.bodyText2}>
                      {rhsQuestion.a}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>

      <Container
        className={classes.statsContainer}
        maxWidth={false}
        style={{ paddingBottom: isMobile ? 0 : 150 }}
      >
        <Grid
          container
          align="center"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={6} sm={12} xs={12} className={classes.stats}>
            <Typography className={classes.statText}>£236</Typography>
            <Typography className={classes.subHeader2Text}>
              Average saving per switch*
            </Typography>
          </Grid>
          {/* <Grid item md={4} sm={12} xs={12} className={classes.stats} >
            <Typography className={classes.statText}>
              9/10
            </Typography>
            <Typography className={classes.subHeader2Text}>
              Average user rating
            </Typography>
          </Grid> */}
          <Grid item md={6} sm={12} xs={12} className={classes.stats}>
            <Typography className={classes.statText}>6:33</Typography>
            <Typography className={classes.subHeader2Text}>
              Average time to switch*
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FAQs;
