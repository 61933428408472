import React from 'react';
import clsx from 'clsx';

// Assets
import ApTapLogo from '../../../../assets/images/aptap_logo_2022.png';

// Material UI
import useStyles from './MobileHeader-style';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import QrCodeModalMobile from '../../qrcodemodal/QrCodeModalMobile';

var mixpanel = require('mixpanel-browser');

var path = window.location.pathname;
// var extensions = ["/banks", "/suppliers", "/blog", "/news", "/article", "/about-us", "/contact"];
var extensions = [];

  export default function MobileHeader() {
    const [hasScrolled, setHasScrolled] = React.useState(window.scrollY > 20);
    React.useEffect(() => {
      const onScroll = (e) => setHasScrolled(window.scrollY > 20);
      window.addEventListener("scroll", onScroll);

      return () => window.removeEventListener("scroll", onScroll);
    }, []);
    const classes = useStyles({ hasScrolled, isExtensionPage: extensions.includes(path) });
    const [state, setState] = React.useState({ left: false });

    let page;

    switch (location.pathname) {
      case '/mobileapp':
        page = 'Mobile App';
        break;
      case '/privacy-policy':
        page = 'Privacy Policy';
        break;
      case '/terms-and-conditions':
        page = 'Terms & Conditions';
        break;
      default:
        page = 'Blog';
        break;
    }
  
    const toggleDrawer = (anchor, open) => (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      setState({ ...state, [anchor]: open });
    };

    const [modalOpen, setModalOpen] = React.useState(false);

    // Once Home.js has been refactored into a functional component,
    // we can put this function in it instead.
    const handleModalOpen = () => {
      setModalOpen(true);

      mixpanel.landing.track('Get The App Button', {
        Page: page,
        Position: 'Nav',
      });
    }

    const handleModalClose = () => setModalOpen(false);

    const list = (anchor) => (
      <div
        className={clsx(classes.drawer)}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <Button onClick={() => {window.location.href = "/";}} className={classes.menuAptapButton}>
          <img src={ApTapLogo} className={classes.logoDrawer} alt="ApTap Logo" />
        </Button> 

        <List className={classes.list}>

          <ListItem button onClick={() => {window.location.href = "/blog"; mixpanel.track("Blog Page", {"navigatedFrom": window.location.href + " (header)"});}}>
            <Typography variant="body1">
              Blog
            </Typography>
          </ListItem>

          <ListItem button onClick={() => {window.location.href = "/about-us"; mixpanel.track("About Us Page", {"navigatedFrom": window.location.href + " (header)"});}}>
            <Typography variant="body1">
              About us
            </Typography>
          </ListItem>

          <Button variant="contained" color="primary" className={classes.getTheAppButton}
            onClick={handleModalOpen}
          >
            <Typography variant="body1" className={classes.getTheAppButtonText}>
              Get the app
            </Typography>
          </Button>

        </List>

      </div>
    );
  
    return (
      <div className={classes.bar}>
        <QrCodeModalMobile isMobile={true} currentPage={'ApTap'} modalOpen={modalOpen} handleModalClose={handleModalClose} />
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Button onClick={() => window.location.href = "/"} className={classes.aptapButton}>
              <img src={ApTapLogo} className={classes.logo} alt="logo" />
            </Button>
            <div className={classes.menu}>
              {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    onClick={toggleDrawer(anchor, true)}
                    style={{ marginRight: -12 }}
                    aria-label="Menu icon"
                    size="large">
                    <MenuIcon fontSize="large"
                      style={{ fill: !hasScrolled ? extensions.includes(path) ? '#FFFFFF' : '#494D64' : '#494D64'}}
                    />
                  </IconButton>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    {list(anchor)}
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
}