import { makeStyles } from "@mui/styles";
import * as colors from "../../../constants/colors";

const styles = makeStyles((theme) => ({
  // main styles
  root: {
    height: "800px",
    [theme.breakpoints.down("md")]: {
      height: "unset",
    },
  },
  lhs: {
    height: "100%",
    float: "left",
    width: "50%",
    paddingLeft: "200px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      float: "initial",
      width: "100%",
      padding: "0px",
      margin: "0 auto",
      height: "350px",
    },
  },
  rhs: {
    height: "800px",
    float: "right",
    width: "50%",
    borderRadius: "0px 0px 0px 100px",
    background:
      "linear-gradient(135deg, rgba(249,121,185,1) 0%, rgba(213,123,255,1) 50%, rgba(16,156,241,1) 100%)",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      float: "initial",
      width: "100%",
      height: "350px",
      borderRadius: "0px 100px 0px 100px",
    },
  },
  learnMore: {
    width: "100%",
    padding: "20px 0",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  controlCenter: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  cardContainer: {
    padding: "0 200px 100px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  blockIcon: {
    width: 156,
    height: 156,
    [theme.breakpoints.down("md")]: {
      width: 103,
      height: 103,
    },
  },

  // typography styles
  headerText: {
    color: "#7744E2",
    fontFamily: "Work Sans",
    fontWeight: 700,
    fontSize: 64,
    lineHeight: "75px",
    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
      lineHeight: "28px",
      padding: "0 16px",
      textAlign: "center",
    },
  },
  infoText: {
    color: "#000e28",
    fontFamily: "Work Sans",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "31px",
    lineHeight: "140px",
    // [theme.breakpoints.down('md')]: {
    //   fontSize: "25px",
    //   lineHeight: "120px",
    // },
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
      lineHeight: "110px",
    },
  },
  subHeader1Text: {
    color: "#000e28",
    fontFamily: "Work Sans",
    fontWeight: "600",
    fontStyle: "bold",
    fontSize: "48px",
    lineHeight: "52px",
    padding: "55px 0 80px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
      lineHeight: "24px",
      textAlign: "center",
      padding: "0 16px",
    },
  },
  subHeader2Text: {
    color: "#000e28",
    fontFamily: "Work Sans",
    fontWeight: "600",
    fontStyle: "normal",
    fontSize: "36px",
    margin: "0 auto",
    marginTop: "12px",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
      lineHeight: "25px",
    },
  },
  bodyText: {
    color: "#5C6474",
    fontFamily: "Work Sans",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "24px",
    marginTop: "12px",
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      lineHeight: "18px",
    },
  },
  span: {
    margin: "20px 0px",
    height: "5px",
    width: "260px",
    backgroundColor: "#000e28",
    position: "absolute",
  },

  // image and card styles
  whiteCard: {
    width: "340px",
    height: "380px",
    borderRadius: "20px",
    padding: "0px",
    [theme.breakpoints.down("md")]: {
      width: "340px",
      height: "300px",
      marginBottom: "20px",
    },
  },
  blueCard: {
    width: "340px",
    height: "380px",
    borderRadius: "20px",
    padding: "0px",
    background: "linear-gradient(135deg, #345995 0%, #0C236E 66.38%)",
    [theme.breakpoints.down("md")]: {
      width: "340px",
      height: "300px",
      marginBottom: "20px",
    },
  },
  screens: {
    width: 600,
    [theme.breakpoints.down("md")]: {
      width: 305,
    },
  },
  downIcon: {
    margin: "-10px auto 0",
  },
  gearIcon: {
    height: "125px",
    margin: "37.5px 0",
    [theme.breakpoints.down("md")]: {
      height: "100px",
      margin: "30px 0",
    },
  },
  scalesIcon: {
    height: "150px",
    margin: "25px 0",
    [theme.breakpoints.down("md")]: {
      height: "120px",
      margin: "20px 0",
    },
  },
  switchIcon: {
    height: "130px",
    margin: "35px 0",
    [theme.breakpoints.down("md")]: {
      height: "104px",
      margin: "28px 0",
    },
  },

  // button styles
  button: {
    backgroundColor: "#7744E2",
    color: "white",
    width: 256,
    height: 40,
    marginTop: 20,
    boxShadow:
      "0px 0px 2px rgba(0, 14, 40, 0.04), 0px 2px 4px rgba(92, 100, 116, 0.16)",
    textTransform: "none",
    borderRadius: 16,
    "&:hover": {
      backgroundColor: "#7744E2",
      boxShadow: "none",
    },
    // [theme.breakpoints.down('md')]: {
    //   width: "180px",
    //   height: "40px",
    // },
    [theme.breakpoints.down("md")]: {
      margin: "30px auto 40px",
      // marginTop: "20px",
      width: "140px",
      height: "32px",
    },
  },
  buttonText: {
    color: "#FFFFFF",
    fontFamily: "Work Sans",
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "19px",
    lineHeight: "14px",
  },
  getTheAppButtonPurple: {
    fontFamily: "Work Sans",
    fontWeight: 700,
    fontSize: 20,
    marginTop: 30,
    marginBottom: (props) => (props.isMobile ? 80 : 0),
    width: 214,
    height: 54,
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.25)",
    textTransform: "unset !important",
    borderRadius: 52,
    background: "#8951E7",
    color: "white",
    "&:hover": {
      background: "#8951E7",
      boxShadow: "none",
    },
  },
  bubbleContainer: {
    backgroundColor: colors.WHITE,
    borderRadius: "50%",
    aspectRatio: "1",
  },
}));

export default styles;
