import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
  root: {
    background: '#ffffff'
  },
  top: {
    background: '#ffffff',
  },
  main: {
    background: '#ffff',
  },
  bottom: {
    background: '#ffffff'
  },

  title: {
    color: '#000E28',
    fontSize: 48,
    fontFamily: 'Work Sans',
    padding: '80px 16px 0',
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
      padding: '24px 16px 0'
    }
  },

  content: {
    maxWidth: 1920,
    fontFamily: 'Work Sans',
    display: 'flex',
    margin: '0 auto',
    padding: '32px 200px',
    '& p': {
      color: '#5C6474',
      fontSize: 24,
      fontFamily: 'Work Sans',
      [theme.breakpoints.down('md')]: {
        fontSize: 16
      }
    },
    [theme.breakpoints.down('lg')]: {
      width: '940px',
    },
    [theme.breakpoints.down('md')]: {
      width: '600px',
      padding: '24px 0',
      margin: 0
    },
    [theme.breakpoints.down('sm')]: {
      width: '360px',
    }
  },
  author: {
    paddingBottom: '50px',
    display: 'flex',
    alignItems: 'center'
  },
  date: {
    fontFamily: 'Work Sans',
    paddingBottom: '50px',
  }

}));

export default styles;
