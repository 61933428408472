import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
    root: {
        height: 'unset !important',
        background: '#ffffff',
    },
    top: {
        background: '#ffffff',
        width: '100%',
    },
    bottom: {
        background: '#ffffff',
        width: '100%',
    }
}));

export default styles;
