import { aptapColors } from "../design/materials/exports";

export const PROGRESS1_TEXT = '#1478CD';
export const PROGRESS1_PALE_TEXT = '#9FD7F9';

export const PROGRESS2_TEXT = '#02893B';
export const PROGRESS2_PALE_TEXT = '#A9DFBF';

export const PROGRESS3_TEXT = '#109CF1';
export const PROGRESS3_PALE_TEXT = '#FFE3B5';

export const BAR0_TEXT = '#FFB946';
export const BAR0_PALE_TEXT = 'rgba(197, 33, 29, 0.3)';

export const BAR1_TEXT = '#537EB6';
export const BAR1_PALE_TEXT = 'rgba(83, 126, 182, 0.3)';

export const BAR2_TEXT = '#74AF52';
export const BAR2_PALE_TEXT = 'rgba(116, 175, 82, 0.3)';

export const BAR3_TEXT = '#884EA1';
export const BAR3_PALE_TEXT = 'rgba(136, 78, 161, 0.3)';

export const BAR4_TEXT = '#E47F17';
export const BAR4_PALE_TEXT = 'rgba(228, 127, 23, 0.3)';

export const BAR5_TEXT = '#DE81BD';
export const BAR5_PALE_TEXT = 'rgba(222, 129, 189, 0.3)';

export const GREENCARDCOLOR = '#0AA44F';
export const BESTCARDCOLOR = '#CC6600';
export const CHEAPESTCARDCOLOR = '#0E9AF1';
export const FASTESTCARDCOLOR = '#0AA44F';

export const WARNING_TEXT = '#E52323';

export const MATERIALGRAY_COLOR = '#ECECEC';
// export const DASHBOARD_COLOR = '#F3F3F3';
export const DASHBOARD_COLOR = aptapColors.BACKGROUND_BLUE;
export const TEXT2_ENERGY_COLOR = '#F7A2A2';
export const TEXT_ENERGY_COLOR = '#FF8E7C';
export const TEXT3_ENERGY_COLOR = '#A73624';
export const TEXT4_ENERGY_COLOR = '#FFCFC7';
export const TEXT5_ENERGY_COLOR = '#D23117';

export const MAIN_COLOR = '#002F7A';
export const MAIN_DASHBOARD_COLOR = '#F3F3F3';
export const TSB_DARK_BLUE = '#28387A';
export const FREE_SPEECH_BLUE = '#3F51B5';
export const DODGER_BLUE = '#1890FF';
export const SUMMER_SKY = '#40A9FF';
export const SUMMER_SKY_PALE = '#47ADE6';
export const SUMMER_SKY_DULL = '#3CA5E7';
export const CERULEAN_BLUE = '#2E58AF';
export const CORNFLOWER_BLUE = '#4487FF'; // rgb(68, 135, 255)
export const CORNFLOWER_BLUE_DARKISH = '#4485FA';
export const RAZZMATAZZ = '#F00148';
export const TANGAROA = '#122740';
export const MID_GREY = '#606F7B';
export const DIM_GREY = '#666666';
export const DIM_GREY2 = '#DADCE2';
export const WHISPER = '#E8E8E8';
export const WHITE = '#FFFFFF';
export const BALI_HAI = '#8795A1';
export const DARK_TANGERINE = '#FFB318';
export const FRUIT_SALAD = '#4CAF51';
export const BITTERSWEET = '#F26464';
export const BLACK = '#000000';
export const MAYA_BLUE = '#70CCFF';
export const ALICE_BLUE = '#F3F6FC';
export const ALICE_BLUE_PALE = '#EFF2F5';
export const EUCALYPTUS = '#27AE60';
export const CREAM_CAN = '#F6BA46';
export const SLATE_BLUE = '#6957C1';
export const ROYAL_BLUE = '#3D68E1';
export const NIGHT_RIDER = '#333333';
export const SPINDLE = '#B8C2CC';
export const GAMBOGE = '#DEA806';
export const GAMBOGE_DARK = '#DE9C00';
export const MEDIUM_SEA_GREEN = '#42B874'; //rgba(66, 184, 116, 1)
export const FROLY = '#F16567';
export const RED_ORANGE = '#F44336';
export const TRANSPARENT = 'transparent';
export const SUNGLOW = '#FFD737';
export const TUNA = '#495057';
export const GREY_CHATEAU = '#9EA9B2';
export const VERY_LIGHT_GREY = '#CCCCCC';
export const BISQUE = '#FFE5C1';
export const BLUE_CHALK = '#F0D4FB';
export const YOUR_PINK = '#FFCCC1';
export const HAWKES_BLUE = '#C8DDFF';
export const PATTENS_BLUE = '#D9E0E7';
export const YELLOW = '#FFFF00';
export const TOGGLEBUTTONCOLOR1 = '#70C4F7';
export const TOGGLEBUTTONCOLOR2 = '#109CF1';
export const WARMINGCOLOR = '#F26464';
export const WARMINGCOLOR2 = '#E4280E';
export const WARMINGCOLOR3 = '#E4280E';
export const WARMINGCOLOR4 = '#E52323';
export const WARMINGCOLOR5 = '#D11919';
export const SUCCESSCOLOR = '#2ED47A';
export const SUCCESSCOLOR2 = '#6DE1A2';
export const SUCCESSCOLOR3 = '#0AA44F';
export const WHITEDARK = "#F9F9F9";
export const INFOBLUECOLOR = '#006CBE';
export const INFOBLUECOLOR2 = '#E5E5E5';
export const INFOBLUECOLOR3 = '#0C236E';
export const INFOBLUECOLOR4 = '#5291FF';
export const INFOBLUECOLOR5 = '#0D4F87';
export const INFOBLUECOLOR6 = '#006CBE';
export const LINECOLOR = "#EEEEEE";
export const LINEDARKREDCOLOR = "#A73624";
export const LINEDARKREDGREYCOLOR = "#9B6A62";
export const LINEDARKBLUEGREYCOLOR = "#7462E5";
export const LINELIGHTBLUECOLOR = "#1478CD";
export const PRIVACYPURPLE = "#662483";
export const TERMSGREEN = "#109D7C";
export const OVERLAYCOLOR = "#f3f3f3cc";
export const TEXTLIGHTGREY = '#ACACAC';
export const LISTBACKGROUNDGREY = '#F5F5F5';
export const BACKGROUNDBLUE = '#F3F5FF';

export const BACKGROUNDROWGREY = '#DADCE2';
export const DEALNAMESUBTEXT = '#5C6474';

// export const APTAPPRIMARY = '#8951E7';
export const APTAPPRIMARY = aptapColors.BILL_BLUE;
export const APTAPGRADIENTDIAGONAL = 'linear-gradient(150.86deg, #0E9AF1 -37.22%, #4D3AE0 28.27%, #C869F4 99.93%, #DD64BB 166.96%)';
export const APTAPGRADIENTVERTICAL = 'linear-gradient(180deg, #0E9AF1 -37.22%, #4D3AE0 28.27%, #C869F4 99.93%, #DD64BB 166.96%)';

// Barclays Colors Start
export const BARCLAYS_DARK_GRAY = '#586072';
export const BARCLAYS_DARKER_GRAY = '#494D64';
export const BARCLAYS_BLUE = '#0E9AF1';
export const BARCLAYS_DARK = '#15385A';
export const ENERGY_PINK = '#FFAFD7';
export const BROADBAND_PURPLE = '#896AE6';
export const MOBILE_PINK = '#F43E77';
export const HEADER_GRAY = '#F8F9FF';
export const DARK_BLUE = '#424F70';
// Barclays Colors End