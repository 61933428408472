import React, { Component } from "react";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DashboardService from "./services/dashboardService";
import AptapDialog from "./components/UI/Modal/AptapDialog";
import Spinner from "./components/UI/Spinner/Spinner";
import { AuthService } from "./components/OpenId/Service/AuthService";
import { Navigate } from "react-router-dom";
import { INCENTIVE_BANNER_SHOWN } from "./constants";
import { KEY_SWITCH_INCENTIVE_BANNER_CLOSED } from "./helpers/keys";

var mixpanel = require('mixpanel-browser');

const authService = new AuthService();

export const AptapContext = React.createContext();

const logout = () => {
  authService.logout();
  mixpanel.reset();
  localStorage.removeItem("userAuthenticated");
  localStorage.removeItem("userSimple");
  localStorage.removeItem("broadbandQuotes");
  localStorage.removeItem("energyQuotes");
  localStorage.removeItem(INCENTIVE_BANNER_SHOWN);
};

export const AptapMainContext = React.createContext();

export function AptapMainProvider({ children }) {
  const theme = useTheme();
  const browserType = getBrowserType();

  const S1 = useMediaQuery(theme.breakpoints.down('md'));
  const MD1 = useMediaQuery(theme.breakpoints.down(1280));
  const LG1 = useMediaQuery(theme.breakpoints.down(1560));
  const LG2 = useMediaQuery(theme.breakpoints.down(1700));
  const LG3 = useMediaQuery(theme.breakpoints.down(1780));
  const XL1 = useMediaQuery(theme.breakpoints.down(1920));

  const dashboardScreenSize = {
    S: S1,
    MD: browserType.isFirefox ? MD1 : false,
    LG: browserType.isFirefox ? LG1 : false,
    XL: browserType.isFirefox ? XL1 : false,
    LG3: browserType.isFirefox ? LG3 : false

  };
  const dealScreenSize = {
    S: S1,
    MD: browserType.isFirefox ? MD1 : false,
    LG: browserType.isFirefox ? LG1 : false,
    XL: browserType.isFirefox ? XL1 : false,
    LG2: browserType.isFirefox ? LG2 : false
  };

  function getBrowserType() {
    // Opera 8.0+
    const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]" 
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 71
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Blink engine detection
    // const isBlink = (isChrome || isOpera) && !!window.CSS;
    return { isOpera, isFirefox, isSafari, isIE, isEdge, isChrome };
  }
  return (
    < AptapMainContext.Provider value={{ browserType, dashboardScreenSize, dealScreenSize }}>
      {children}
    </AptapMainContext.Provider >
  );
}
export class AptapProvider extends Component {
  state = {
    nonLoginRoutes: ['/energy-quote', '/broadband-quote', '/deals', '/switch'],
    Dashboard: {
      User: {
        Id: 2,
        Name: "Celal",
        SurName: "DAĞDEVİREN"
      }
    },
    Data: {
      customer: {},
      customerBanks: [],
      customerSubscriptions: [],
      customerDashboardIndustyItems: []
    },
    dashboardFetched: false,
    open: false,
    headTitle: "Loading",
    description: "Please wait...",
    isWait: false,
    isError: false,
    redirectPage: false,
    redirectUri: "",
    refreshing: false,
    refreshingError: false,
    showFooter: true,
    showSwitchIncentiveBanner: true,
    showBankExpiredBanner: true
  }

  componentDidMount() {
    this.setState({ open: true, isWait: true });
    if (localStorage.getItem('userAuthenticated') != null) {
      this.getDashboard();
    } else {
      this.setState({ dashboardFetched: true });
    }
    if (localStorage.getItem(KEY_SWITCH_INCENTIVE_BANNER_CLOSED) != null) {
      this.setState({ showSwitchIncentiveBanner: false });
    }
  }

  getDashboard = () => {
    DashboardService.getDashboard()
      .then(response => {
        const broadbandItem = response.data.customerDashboardIndustyItems.find(i => i.industryType === 2);
        const energyItem = response.data.customerDashboardIndustyItems.find(i => i.industryType === 1);
        localStorage.CurrentEnergyPrice = energyItem.detectedPaidAmount ? energyItem.detectedPaidAmount : 0;
        localStorage.CurrentBroadbandPrice = broadbandItem.detectedPaidAmount ? broadbandItem.detectedPaidAmount : broadbandItem.actualPaidAmount1;
        localStorage.CurrentBroadbandProvider = broadbandItem.providerName === "I DO NOT HAVE ONE"
          ? ""
          : broadbandItem.providerName
            ? broadbandItem.providerName.toLowerCase()
            : "";
        // set providers first then identify using customerIdentifier:
        mixpanel.people.set({
          "$broadbandProvider": broadbandItem.providerName ? broadbandItem.providerName : "",
          "$energyProvider": energyItem.providerName ? energyItem.providerName : "",
          "$appType": localStorage.appType
        });
        mixpanel.identify(response.data.customer.customerIdentifier);
        this.setState({
          Data: response.data,
          dashboardFetched: true,
          open: false
        },
          () => {
            if (this.state.Data.customerBanks.length === 0) {
              this.setState({
                redirectPage: true,
                redirectUri: "/banklogin-page-connect"
              });
            }
            else if (this.state.Data.customer.postCode === null ||
              this.state.Data.customer.postCode === "") {
              this.setState({
                redirectPage: true,
                redirectUri: "/banklogin-page-postcode"
              });
            }
          }
        );
      })
      .catch(function (rej) {
        if (rej.data !== undefined && rej.data.statusCode === 2105) {
          logout();
        }
        console.log("error" + JSON.stringify(rej));
      });
  };

  refreshDashboard = async () => {
    try {
      this.setState({ refreshing: true, refreshingError: false })
      const { data } = await DashboardService.refreshDashboard();
      this.setState({ Data: data, refreshing: false });
    } catch (error) {
      this.setState({ refreshing: false, refreshingError: true })


      console.error(error);
    }
  };

  setShowFooter = (value) => {
    this.setState({ showFooter: value });
  }

  setSwitchIncentiveBanner = (value) => {
    this.setState({ showSwitchIncentiveBanner: value });
  }

  setBankExpiredBanner = (value) => {
    this.setState({ showBankExpiredBanner: value });
  }

  render() {
    return this.state.redirectPage
      ? (
        <Navigate to={this.state.redirectUri} />
      )
      : this.state.dashboardFetched
        ? (
          <AptapContext.Provider
            value={{ ...this.state, refreshDashboard: this.refreshDashboard, getDashboard: this.getDashboard, setShowFooter: this.setShowFooter, setSwitchIncentiveBanner: this.setSwitchIncentiveBanner, setBankExpiredBanner: this.setBankExpiredBanner }}
          >
            {this.props.children}
          </AptapContext.Provider>
        )
        : (
          <AptapDialog
            maxWidth={"xs"}
            open={this.state.open}
            onClose={() => {
              this.setState({ open: false });
            }}
          >
            <Spinner
              headTitle={this.state.headTitle}
              description={this.state.description}
              isWait={this.state.isWait}
              isError={this.state.isError}
            />
          </AptapDialog>
        );
  }
}

const AptapConsumer = AptapContext.Consumer;
export default AptapConsumer;
