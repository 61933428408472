import { makeStyles } from "@mui/styles";
import { aptapColors } from "../../../../design/materials/exports";

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: "3",
  },
  bar: {
    background: "transparent",
    boxShadow: (props) =>
      props.hasScrolled
        ? "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
        : null,
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  appBar: {
    background: "white",
    boxShadow: "none",
    width: "100%",
    zIndex: "2",
  },
  toolBar: {
    padding: "0px 20px",
  },
  menu: {
    marginLeft: "auto",
  },

  // aptap button styles
  aptapButton: {
    width: "100px",
    height: "40px",
  },
  menuAptapButton: {
    width: "100px",
    height: "40px",
    position: "relative",
    left: "50%",
    transform: "translateX(-50%)",
    marginBottom: 30,
  },
  logo: {
    height: 40,
    paddingLeft: 20,
  },
  logoDrawer: {
    height: 40,
  },

  // button styles
  euroButton: {
    margin: theme.spacing(1, 0, 2, 2),
    color: "white",
    background: "linear-gradient(132.19deg, #174D82 0%, #1C871C 100.01%)",
    width: "135px",
    height: "30px",
    boxShadow: "none",
    textTransform: "unset !important",
    "&:hover": {
      boxShadow: "none",
    },
  },
  headerLoginButton: {
    color: (props) =>
      !props.hasScrolled
        ? props.isExtensionPage
          ? "#FFFFFF"
          : "#000E28"
        : "#000E28",
    textTransform: "none",
  },
  getTheAppButton: {
    margin: theme.spacing(1, 0, 1, 0),
    marginTop: 60,
    width: "100%",
    height: "36px",
    boxShadow: "none",
    textTransform: "none",
    borderRadius: 16,
    backgroundColor: aptapColors.LIGHT_RED,
    "&:hover": {
      backgroundColor: aptapColors.LIGHT_RED,
    },
  },
  getTheAppButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: 700,
  },
  drawer: {
    width: 200,
    backgroundColor: aptapColors.BACKGROUND_BLUE,
    color: aptapColors.BILL_BLUE,
    height: "100%",
    paddingTop: 24,
  },
  list: {
    padding: theme.spacing(1, 2),
    "& .MuiListItem-gutters": {
      paddingLeft: 0,
    },
  },
  divider: {
    margin: "24px 0",
    borderTop: "2px solid rgba(236, 236, 236, 20%)",
  },
}));

export default styles;
