import { makeStyles } from "@mui/styles";
import * as colors from "../../../constants/colors";

const useStyles = makeStyles(() => ({
  qrModalContainer: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%,-50%)',
    width: props => props.currentPage === 'Network'
      ? '100%'
      : props.isMobile
        ? '75%'
        : '60%',
    height: props => props.currentPage === 'Network'
      ? '100%'
      : '',
    backgroundColor: 'white',
    borderRadius: props => props.currentPage === 'Network'
      ? 0
      : props.isMobile
        ? 30
        : 60,
    padding: props => props.isMobile ? '10% 4%' : '40px 0',
  },
  qrCodeContainer: {
    padding: 20,
    borderRadius: 50,
    boxShadow: '12px 23px 62px rgba(0, 0, 0, 0.15)',
  },
  qrCode: {
    height: 275,
    width: 275,
  },

  headerPurple: {
    color: colors.APTAPPRIMARY,
    fontFamily: 'Work Sans',
    fontWeight: 700,
    fontSize: props => props.isMobile ? 60 : 60,
    lineHeight: '92px',
    textAlign: props => props.isMobile ? 'center' : '',
    marginBottom: props => props.isMobile ? 10 : 40,
  },
  modalHeader: {
    color: colors.APTAPPRIMARY,
    fontFamily: 'Work Sans',
    fontWeight: 700,
    fontSize: 40,
    lineHeight: '46px',
    textAlign: 'center',
    marginBottom: 10,
  },
  modalContent: {
    color: '#586072',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    fontSize: 25,
    lineHeight: '32px',
    textAlign: 'center',
    padding: '20px 0 30px',
  },

  alternateRegularText: {
    color: '#586072',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    fontSize: props => props.isMobile ? 20 : 25,
    textAlign: props => props.isMobile ? 'center' : '',
  },
}));

export { useStyles };