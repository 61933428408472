import React from 'react';
import 'typeface-work-sans';

// Assets
import LandingCalendar from '../../../../assets/images/landing/LandingBills.webp';

// Material-UI
import Typography from '@mui/material/Typography';
import useStyles from './Banner-style';
import Grid from '@mui/material/Grid';
import { Button, useMediaQuery, useTheme } from '@mui/material';

function Banner(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" style={{ padding: isMobile ? '0 16px' : '0 200px' }}>
        <Grid container item xs={isMobile ? 12 : 7} direction="column">
          <Grid item><Typography className={classes.header}>Help your customers save while driving revenue and engagement</Typography></Grid>
          <Grid item><Typography className={classes.bodyText} style={{ marginBottom: 32 }}>Millions of Brits are overspending on their bills and subscriptions by billions of pounds! They’re tough to track and it’s even harder to find the best deals on the market.</Typography></Grid>
          <Grid item><Typography className={classes.bodyText} style={{ marginBottom: 32 }}>ApTap empowers your customers; helping them cancel, compare, switch, and sign up to services without ever leaving your app. ApTap boosts engagement and allows you to tap into affiliate revenue.</Typography></Grid>
          <Grid container item justifyContent={isMobile ? 'center' : null}>
            <Button onClick={props.scrollToContactForm} className={classes.contactUsButton}>
              <Typography>Contact us</Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs={isMobile ? 12 : 5} justifyContent="center">
          <img src={LandingCalendar} alt="ApTap's calendar feature" style={{ maxWidth: isMobile ? 344 : 305 }} />
        </Grid>
      </Grid>
    </div>
  );
}

export default Banner;
