import React, { useEffect, useState } from 'react';
import 'typeface-work-sans';

// Components
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import MobileHeader from '../components/mobileHeader/MobileHeader';
import Articles from "./articles/Articles";

// Material-UI
import useStyles from './Blog-style';
import Hidden from '@mui/material/Hidden';

var mixpanel = require("mixpanel-browser");

const Blog = () => {
  const classes = useStyles();
  const contactRef = React.createRef();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    mixpanel.landing.track('Blog Page');
  }, []);

  const scrollToContactForm = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <Header />
      </Hidden>
      <Hidden mdUp>
        <MobileHeader scrollToContactForm={scrollToContactForm} />
      </Hidden>

      <div className={classes.main}>
        <Articles setIsLoading={setIsLoading} />
      </div>

      {!isLoading &&
        <div ref={contactRef} className={classes.bottom}>
          <section id="footer">
            <Footer />
          </section>
        </div>
      }
    </div>
  );
}

export default Blog;
