import React, { useEffect } from 'react';
import { getMobileOperatingSystem } from '../../../helpers/getMobileOperatingSystem';

var mixpanel = require('mixpanel-browser');

const QrCodeRedirect = () => {
  let searchParam = '';

  const networkParam = ['?hsd', '?dft', '?dfc', '?dfcw'];

  if (window.location.pathname === '/network') {
    if (networkParam.includes(window.location.search)) {
      searchParam = `_${window.location.search.substring(1)}`;
    }
  }

  const deviceUsed = getMobileOperatingSystem();

  switch (deviceUsed) {
    case 'Android':
      window.location.href = window.location.pathname === '/network'
        ? `https://play.google.com/store/apps/details?id=com.aptap.mobile&referrer=utm_source%3Dnetwork${searchParam}%26utm_medium%3Dlanding_page%26anid%3Daarki%26aclid%3D{click_id}%26cp1%3D{app_id}`
        : 'https://play.google.com/store/apps/details?id=com.aptap.mobile';
      break;
    case 'iOS':
      window.location.href = window.location.pathname === '/network'
        ? `https://apps.apple.com/app/apple-store/id1596457853?pt=119145408&ct=network${searchParam}&mt=8`
        : 'https://apps.apple.com/app/aptap/id1596457853';
      break;
    default:
      window.location.href = '/';
      break;
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get('utm_source');
    const utmMedium = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');
  
    if (utmSource && utmMedium && utmCampaign) {
      mixpanel.track('Download Link Clicked', {
        'UTM Source': utmSource,
        'UTM Medium': utmMedium,
        'UTM Campaign': utmCampaign,
      });
    } else mixpanel.track("App Store Click", { "Clicked button": 'QR Code' });
  }, []);

  return (
    <div />
  );
};

export default QrCodeRedirect;
