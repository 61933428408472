import React, { useEffect } from 'react';
import { Grid, Modal, Typography } from '@mui/material';

import { useStyles } from "./QrCodeModalStyles";

import QrCodeApTap from '../../../assets/images/landing/QrCodeApTap.webp';
import QrCodeTsb from '../../../assets/images/landing/QrCodeTsb.webp';

var mixpanel = require('mixpanel-browser');

const QrCodeModalDesktop = ({ isMobile, currentPage, modalOpen, handleModalClose }) => {
  const classes = useStyles({ isMobile, currentPage });

  useEffect(() => {
    if (modalOpen) {
      mixpanel.track("Button Displayed", { "Displayed button": "QR Code", "Landing page": currentPage });
    }
  }, [modalOpen, currentPage]);

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby='Get the app'
      aria-describedby='Get the app with QR code'
    >
      <Grid container item justifyContent='space-evenly' alignItems='center' className={classes.qrModalContainer}>
        <Grid item>
          <Typography className={classes.headerPurple}>Get the app!</Typography>
          <Typography className={classes.alternateRegularText}>
            We’ve just released our new mobile app. Follow the store link<br />or QR code to get the best version of ApTap!
          </Typography>
        </Grid>
        <Grid item>
          <img src={currentPage === 'TSB' ? QrCodeTsb : QrCodeApTap} className={classes.qrCode} alt="A QR code to download ApTap" />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default QrCodeModalDesktop;
