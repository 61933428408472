import { Button, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useStyles from './SuppliersContent-style';

import EngagementGraphic from '../../../assets/images/landing_img/engagement_graphic.png';
import TrustedEnvironment from '../../../assets/images/landing_img/trusted_environment.png';
import TrustedEnvironmentMobile from '../../../assets/images/landing_img/trusted_environment_mobile.png';
import CustomerIcon from '../../../assets/images/landing_img/customer_icon.png';
import ConversionIcon from '../../../assets/images/landing_img/conversion_icon.png';
import BehavourIcon from '../../../assets/images/landing_img/behavour_icon.png';
import CostsIcon from '../../../assets/images/landing_img/costs_icon.png';
import ImproveIcon from '../../../assets/images/landing_img/improve_icon.png';
import TSBLogo from '../../../assets/images/logos/tsb_logo.png';
import TrueLayerLogo from '../../../assets/images/logos/truelayer_logo.png';
import AwinLogo from '../../../assets/images/logos/awin_logo.png';
import NetworkLogo from '../../../assets/images/logos/network_logo.png';
import AccentureLogo from '../../../assets/images/logos/accenture_logo.png';
import ImperialLogo from '../../../assets/images/logos/imperial_logo.png';
import InnovateUKLogo from '../../../assets/images/logos/innovateuk_logo.png';

function SuppliersContent(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Grid container className={classes.root}>
                <Grid container alignItems="center" style={{ padding: isMobile ? '0 16px' : '0 200px' }}>
                    <Grid container item xs={isMobile ? 12 : 7} direction="column">
                        <Grid item><Typography className={classes.header}>Improve customer acquisition, engagement and retention</Typography></Grid>
                        <Grid item><Typography className={classes.bodyText} style={{ marginBottom: 32 }}>Use our actionable insights to future proof your services, drive customer acquisition and retention, and communicate with your customers within their banking app. </Typography></Grid>
                        <Grid container item justifyContent={isMobile ? 'center' : null}>
                            <Button onClick={props.scrollToContactForm} className={classes.contactUsButton} style={{ backgroundColor: '#7744E2' }}>
                                Contact Us
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container item xs={isMobile ? 12 : 5} justifyContent="center">
                        <img src={EngagementGraphic} alt="" style={{ maxWidth: isMobile ? 344 : 615, paddingBottom: isMobile ? 32 : null }} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.suppliersContentContainer}>
                <Grid container direction={isMobile ? 'column' : 'row'} justifyContent="center" align="center" className={classes.blockContainerMargin64}>
                    <Grid container item direction="column" className={classes.blockItem}>
                        <Grid item><img src={CustomerIcon} className={classes.blockIcon} alt="" /></Grid>
                        <Grid item><Typography className={classes.blockTitle}>New customer acquisition channels</Typography></Grid>
                        <Grid item><Typography className={classes.blockText}>Access to over 7 million new and existing customers</Typography></Grid>
                    </Grid>
                    <Grid container item direction="column" className={classes.blockItem}>
                        <Grid item><img src={ConversionIcon} className={classes.blockIcon} alt="" /></Grid>
                        <Grid item><Typography className={classes.blockTitle}>Increase conversion rates</Typography></Grid>
                        <Grid item><Typography className={classes.blockText}>Our frictionless customer experience  allows customers to switch or sign-up in seconds</Typography></Grid>
                    </Grid>
                    <Grid container item direction="column" className={classes.blockItem}>
                        <Grid item><img src={BehavourIcon} className={classes.blockIcon} alt="" /></Grid>
                        <Grid item><Typography className={classes.blockTitle}>Richer behavioural insights</Typography></Grid>
                        <Grid item><Typography className={classes.blockText}>Access to behavioural insights can help you tailor and improve your offerings</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid container item direction={isMobile ? 'column' : 'row'} justifyContent="center" align="center" className={classes.blockContainer}>
                    <Grid container item direction="column" className={classes.blockItem} style={{ marginRight: isMobile ? null : 64 }}>
                        <Grid item><img src={CostsIcon} className={classes.blockIcon} alt="" /></Grid>
                        <Grid item><Typography className={classes.blockTitle}>Reduce costs</Typography></Grid>
                        <Grid item><Typography className={classes.blockText}>Reduce the time spent chasing users for payments by communicating with customers in the same place they manage their money</Typography></Grid>
                    </Grid>
                    <Grid container item direction="column" className={classes.blockItem}>
                        <Grid item><img src={ImproveIcon} className={classes.blockIcon} alt="" /></Grid>
                        <Grid item><Typography className={classes.blockTitle}>Improve customer engagement</Typography></Grid>
                        <Grid item><Typography className={classes.blockText}>Communicate directly with your customers through your embedded merchant profile</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid container item className={classes.suppliersImageContainer}>
                    {isMobile && <img src={TrustedEnvironmentMobile} alt="ApTap's partners" style={{ width: 'calc(100% + 16px)', margin: '0 -8px' }} />}
                    {!isMobile && <img src={TrustedEnvironment} alt="ApTap's partners" style={{ width: '100%' }} />}
                </Grid>
                <Grid container item alignItems="center" className={classes.blockContainer}>
                    <Typography className={classes.headerCenter}>Connect with new and existing customers within a trusted environment</Typography>
                    <Typography className={classes.bodyText} style={{ marginBottom: 32 }}>ApTap provides an intersection between banking data and merchant data, to deliver a next-generation platform to help you better understand customer behaviour and improve products and services.</Typography>
                    <Typography className={classes.bodyText} style={{ marginBottom: 32 }}>Connecting with customers in a qualified environment increases valuable touch points, particularly for payment related issues. </Typography>
                    <Grid container item justifyContent="center">
                        <Button onClick={props.scrollToContactForm} className={classes.contactUsButton} style={{ backgroundColor: '#CB6CE6' }}>
                            Contact Us
                        </Button>
                    </Grid>
                </Grid>
                <Grid container item direction="column" alignItems="center" className={classes.blockContainer} style={{ marginTop: -30 }}>
                    <Grid container item justifyContent="center"><Typography className={classes.trustedBy}>Trusted by</Typography></Grid>
                    <Grid container item alignItems="center" justifyContent="center" spacing={4} style={{ padding: isMobile ? '20px 0 40px' : '0 140px', margin: isMobile ? null : '0 -32px' }}>
                        <img src={TSBLogo} alt="TSB's logo" style={{ width: isMobile ? 101 : 164, margin: isMobile ? '0 16px' : '0 32px' }} />
                        <img src={TrueLayerLogo} alt="TrueLayer's logo" style={{ width: isMobile ? 138 : 326, margin: isMobile ? '0 16px' : '0 32px' }} />
                        <img src={NetworkLogo} alt="Network's logo" style={{ width: isMobile ? 220 : 550, margin: isMobile ? '16px 16px' : '0 32px' }} />
                        <img src={AccentureLogo} alt="Accenture's logo" style={{ width: isMobile ? 82 : 202, margin: isMobile ? '0 16px' : '0 32px' }} />
                        <img src={AwinLogo} alt="Awin's logo" style={{ width: isMobile ? 96 : 180, margin: isMobile ? '0 16px' : '24px 32px 0' }} />
                        <img src={InnovateUKLogo} alt="Innovate UK's logo" style={{ width: isMobile ? 140 : 250, margin: isMobile ? '0 16px' : '0 32px' }} />
                        <img src={ImperialLogo} alt="Imperial's logo" style={{ width: isMobile ? 98 : 226, margin: isMobile ? '0 16px' : '0 32px 0' }} />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default SuppliersContent;