/* CUSTOM COLORS USED IN CUSTOM APTAP COMPONENTS  */
export const GENERAL_WARNING = "#F26464";
export const INFOBLUECOLOR = "#006CBE";

export const ENERGY_CARD_BACKGROUND = "#E4280E";
export const ENERGY_CARD_BUTTON_BACKGROUND = "#E4280E";
export const ENERGY_CARD_BUTTON_BACKGROUND2 = "#FFEFEC";
export const ENERGY_CARD_LABEL_TEXT = "#A73624";

export const SUBSCRIPTION_UPCOMING_CARD_BACKGROUND = "#F3E8E8";
export const SUBSCRIPTION_UPCOMING_LATER_CARD_BACKGROUND = "#9FD7F9";
export const SUBSCRIPTION_PAID_CARD_BACKGROUND = "#83e3b029";
export const SUBSCRIPTION_MANUAL_CARD_BACKGROUND = "#9FD7F9";
export const SUBSCRIPTION_INACTIVA_CARD_BACKGROUND = "#f264641c";

export const BROADBAND_CARD_BACKGROUND = "#4A30EB";
export const BROADBAND_CARD_LIGHT_BACKGROUND = "#4A30EB60";
export const BROADBAND_COLOR_LIGHT1 = "#8E7CFF";
export const BROADBAND_INNERCARD_TABBACKGROUND = "#CFC7FF";
export const BROADBAND_CARD_BACKGROUND_LIGHT = "#6fc2f6";
export const BROADBAND_CARD_BUTTON_BACKGROUND = "#4A30EB";
export const BROADBAND_CARD_BUTTON_BACKGROUND2 = "#EFECFF";
export const BROADBAND_CARD_LABEL_TEXT = "#4A30EB";

export const DEAL_LESS_PAID_CARD_BACKGROUND = "#EAFBF2";
export const DEAL_MORE_PAID_CARD_BACKGROUND = "#FFEFEC";
export const DEAL_INFO_CARD_BACKGROUND = "#FFF8EC";

export const CALANDERTEXT1 = "#000E28";

/* BRAND */
export const BRAND_PRIMARY = "#FF2773";
export const BRAND_PRIMARYDARK1 = "#FF005A";
export const BRAND_PRIMARYDARK2 = "#E75A8C";
export const BRAND_PRIMARYDARK3 = "#CC396D";

export const BRAND_PRIMARYLIGHT = "#FF4D95";
export const BRAND_PRIMARYLIGHT1 = "#FF7EAC";
export const BRAND_PRIMARYLIGHT2 = "#FFC7DB";
export const BRAND_PRIMARYLIGHT3 = "#CC396D";

export const BRAND_SECONDARY = "#2B1E70";
export const BRAND_SECONDARYDARK1 = "#FF005A";
export const BRAND_SECONDARYDARK2 = "#E75A8C";
export const BRAND_SECONDARYDARK3 = "#CC396D";

export const BRAND_SECONDARYLIGHT = "#252D9B";
export const BRAND_SECONDARYLIGHT1 = "#FF005A";
export const BRAND_SECONDARYLIGHT2 = "#E75A8C";
export const BRAND_SECONDARYLIGHT3 = "#CC396D";

/* UI TEXT */
export const UITEXT_PRIMARY = "#4487FF";
export const UITEXT_PRIMARYDARK1 = "#FF005A";
export const UITEXT_PRIMARYLIGHT1 = "#7CABFF";
export const UITEXT_PRIMARYLIGHT2 = "#CC396D";
export const UITEXT_PRIMARYLIGHT3 = "#ECF3FF";

export const UITEXT_SECONDARY = "#685879";
export const UITEXT_SECONDARYDARK1 = "#FF005A";
export const UITEXT_SECONDARYLIGHT1 = "#E75A8C";
export const UITEXT_SECONDARYLIGHT2 = "#CC396D";

export const UITEXT_WHITE = "#FFFFFF";
export const UITEXT_WHITEDARK1 = "#FF005A";
export const UITEXT_WHITEDARK2 = "#E75A8C";
export const UITEXT_WHITEDARK3 = "#CC396D";

export const UITEXT_DARK = "#000000";
export const UITEXT_DARKLIGHT1 = "#494A4C";
export const UITEXT_DARKLIGHT2 = "#898F99";
export const UITEXT_DARKLIGHT3 = "#CC396D";
export const UITEXT_DARKLIGHT4 = "#5C6474";

export const UITEXT_SUCCESS = "#5CC689";
export const UITEXT_SUCCESSDARK1 = "#FF005A";
export const UITEXT_SUCCESSLIGHT1 = "#E75A8C";
export const UITEXT_SUCCESSLIGHT2 = "#CC396D";

export const UITEXT_DANGER = "#EE7D52";
export const UITEXT_DANGERDARK1 = "#FF005A";
export const UITEXT_DANGERLIGHT1 = "#E75A8C";
export const UITEXT_DANGERLIGHT2 = "#CC396D";

export const UITEXT_LINK = "#252D9B";
export const UITEXT_LINKDARK1 = "#FF005A";
export const UITEXT_LINKLIGHT1 = "#E75A8C";
export const UITEXT_LINKLIGHT2 = "#CC396D";

/* UI ELEMENT */
export const UIELEMENT_PRIMARY = "#4487FF";
export const UIELEMENT_PRIMARYDARK1 = "#FF005A";
export const UIELEMENT_PRIMARYLIGHT1 = "#7CABFF";
export const UIELEMENT_PRIMARYLIGHT2 = "#ECF3FF";

export const UIELEMENT_SECONDARY = "#FFBC44";
export const UIELEMENT_SECONDARYDARK1 = "#FF005A";
export const UIELEMENT_SECONDARYLIGHT1 = "#E75A8C";
export const UIELEMENT_SECONDARYLIGHT2 = "#CC396D";

export const UIELEMENT_TERTIARY = "#B3ABBC";
export const UIELEMENT_TERTIARYDARK1 = "#FF005A";
export const UIELEMENT_TERTIARYLIGHT1 = "#E75A8C";
export const UIELEMENT_TERTIARYLIGHT2 = "#CC396D";

export const UIELEMENT_SUCCESS = "#5CC689";
export const UIELEMENT_SUCCESSDARK1 = "#FF005A";
export const UIELEMENT_SUCCESSLIGHT1 = "#E75A8C";
export const UIELEMENT_SUCCESSLIGHT2 = "#CC396D";

export const UIELEMENT_DANGER = "#EE7D52";
export const UIELEMENT_DANGERDARK1 = "#FF005A";
export const UIELEMENT_DANGERLIGHT1 = "#E75A8C";
export const UIELEMENT_DANGERLIGHT2 = "#CC396D";

export const UIELEMENT_WHITE = "#EAEAEA";
export const UIELEMENT_WHITEDARK1 = "#FF005A";
export const UIELEMENT_WHITEDARK2 = "#E75A8C";
export const UIELEMENT_WHITEDARK3 = "#CC396D";

export const UIELEMENT_GRAY = "#F7F7F7";

/* UI GRADIENTS */
export const UIGRADIENTS_PRIMARY = "#2B1E70, 80%"; // TODO: %values do not work!
export const UIGRADIENTS_PRIMARYDARK1 = "#FF005A";
export const UIGRADIENTS_PRIMARYLIGHT1 = "#E75A8C";
export const UIGRADIENTS_PRIMARYLIGHT2 = "#CC396D";

export const UIGRADIENTS_SECONDARY = "#2B1E70 80%";
export const UIGRADIENTS_SECONDARYDARK1 = "#FF005A";
export const UIGRADIENTS_SECONDARYLIGHT1 = "#E75A8C";
export const UIGRADIENTS_SECONDARYLIGHT2 = "#CC396D";

export const BACKGROUND_BLUE = "#F3F5FF";
export const BILL_BLUE = "#46218E";
export const LIGHT_RED = "#F43E77";

export const LIGHT_GREY = "#B0B2BB";
export const DARK_GREY = "#707389";
export const DARKER_GREY = "#363C49";
