// Assets
import LandingBroadbandMobile1 from "../../../assets/images/landing/LandingBroadbandMobile1.webp";
import LandingCalendar from "../../../assets/images/landing/LandingBills.webp";
import manageIcon from "../../../assets/images/Manage_icon.png";
import compareIcon from "../../../assets/images/Compare_icon.png";
import switchIcon from "../../../assets/images/Switch_icon.png";

// Material-UI
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import useStyles from "./FeatureStyles";

function Feature({ handleModalOpen }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className={classes.root}>
      <Typography className={classes.featureTitle}>
        Your subscription control centre
      </Typography>
      <Grid
        container
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        wrap="nowrap"
        align="center"
        className={classes.blockContainer}
      >
        <Grid container item direction="column" className={classes.blockItem}>
          <Grid item>
            <img src={manageIcon} className={classes.blockIcon} alt="" />
          </Grid>
          <Grid item>
            <Typography className={classes.blockTitle}>Manage</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.blockText}>
              We automatically find and organise all of your bills using open
              banking technology.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="column" className={classes.blockItem}>
          <Grid item>
            <img src={compareIcon} className={classes.blockIcon} alt="" />
          </Grid>
          <Grid item>
            <Typography className={classes.blockTitle}>Compare</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.blockText}>
              We compare your bills to deals on the market based on your
              spending and service usage.
            </Typography>
          </Grid>
        </Grid>
        <Grid container item direction="column" className={classes.blockItem}>
          <Grid item>
            <img src={switchIcon} className={classes.blockIcon} alt="" />
          </Grid>
          <Grid item>
            <Typography className={classes.blockTitle}>Switch</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.blockText}>
              Take control and simply switch broadband or energy within minutes.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        wrap="nowrap"
      >
        {!isMobile && (
          <Grid container item xs={isMobile ? 12 : 5} justifyContent="center">
            <img
              src={LandingBroadbandMobile1}
              className={classes.image}
              alt="broadband deals"
            />
          </Grid>
        )}
        <Grid
          container
          item
          xs={isMobile ? 12 : 7}
          direction="column"
          alignItems={isMobile ? "center" : ""}
          style={{ marginBottom: isMobile ? 80 : 0 }}
        >
          <Typography className={classes.subHeader1Text} tabIndex={0}>
            Deals from the best providers
          </Typography>
          <Typography className={classes.bodyText} tabIndex={0}>
            We sift through hundreds of deals from the UK’s top service
            providers to make sure you’re only shown the best ones.
            <br />
            <br />
            We do make commission from switching but we will never prioritise
            the deal you see based on the amount we make. ApTap will share this
            commission with TSB Bank.
          </Typography>
          <Button
            variant="contained"
            className={classes.getTheAppButtonBlue}
            onClick={() => handleModalOpen("Button 2")}
          >
            Get the app
          </Button>
        </Grid>

        {isMobile && (
          <img
            src={LandingBroadbandMobile1}
            className={classes.image}
            alt="ApTap's broadband deal comparison feature"
          />
        )}
      </Grid>

      <Grid
        container
        alignItems="center"
        style={{ paddingBottom: isMobile ? 0 : 100 }}
      >
        <Grid
          container
          item
          xs={isMobile ? 12 : 7}
          direction="column"
          justifyContent="center"
          alignItems={isMobile ? "center" : ""}
          style={{ marginBottom: isMobile ? 80 : 0 }}
        >
          <Typography className={classes.subHeader1Text} tabIndex={0}>
            See all your regular payments
          </Typography>
          <Typography className={classes.bodyText} tabIndex={0}>
            See all of your recurring payments in one place, across any of your
            bank accounts, and connect your service supplier accounts to see
            your actual usage and bills.
            <br />
            <br />
            We’ll find the top deals on the market, tailored to your habits, so
            you can sign up or switch services in minutes.
          </Typography>
          <Button
            variant="contained"
            className={classes.getTheAppButtonPurple}
            onClick={() => handleModalOpen("Button 3")}
          >
            Get the app
          </Button>
        </Grid>
        <Grid container item xs={isMobile ? 12 : 5} justifyContent="center">
          <img
            src={LandingCalendar}
            className={classes.image}
            alt="subscriptions"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Feature;
