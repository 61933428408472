import { makeStyles } from "@mui/styles";
import { aptapColors } from "../../../../design/materials/exports";

const styles = makeStyles((theme) => ({
  root: {
    maxWidth: 1920,
    margin: "0 auto",
  },
  contactForm: {
    padding: "64px 200px",
    background: aptapColors.BILL_BLUE,
    [theme.breakpoints.down("md")]: {
      padding: "32px 16px",
    },
  },
  footer: {
    backgroundColor: "#EFEFEF",
    margin: "0 auto",
    padding: "64px 200px 16px",
    [theme.breakpoints.down("md")]: {
      padding: "0 16px 16px",
    },
  },
  link: {
    color: (props) => (props.isDetailed ? "white" : "#5C6474"),
    "&:hover": {
      color: (props) => (props.isDetailed ? "white" : "#5C6474"),
    },
  },
  footerLinks: {
    paddingBottom: 64,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 32,
    },
  },
  footerLinkItem: {
    maxWidth: 356,
    paddingTop: (props) => (props.isMobile ? 32 : null),
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
    },
  },
  footerLinkTitle: {
    color: (props) => (props.isDetailed ? "white" : "#586072"),
    fontSize: 24,
    fontWeight: 500,
    fontFamily: "Work Sans",
    paddingBottom: 24,
  },
  footerLinkText: {
    color: (props) => (props.isDetailed ? "white" : "#586072"),
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "Work Sans",
    minHeight: 48,
  },
  footerSubstantiation: {
    color: (props) => (props.isDetailed ? "white" : "#586072"),
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Work Sans",
    marginBottom: "16px",
  },
  footerLegal: {
    color: (props) => (props.isDetailed ? "white" : "#586072"),
    fontSize: 16,
    fontWeight: 400,
    fontFamily: "Work Sans",
  },
  socialIconButton: {
    width: 32,
    height: 32,
    cursor: "pointer",
    borderRadius: "100%",
  },
  divider: {
    margin: "0 0 16px",
    borderBottom: (props) =>
      props.isDetailed ? "1px solid white" : "1px solid #586072",
  },
}));

export default styles;
