import React from 'react';
import emailjs from 'emailjs-com'
import apiKeys from './MailJsApiKeys'

// Components
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import MobileHeader from '../components/mobileHeader/MobileHeader';

// Material-UI
import { withStyles } from '@mui/styles';
import useStyles from './Contact-style';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Hidden from '@mui/material/Hidden';



class Contact extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      consent: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    
  }

  handleChange(event) {
    const {name, value, type, checked} = event.target
    type === "checkbox"
    ? this.setState({
      [name]: checked
    })
    : this.setState({
      [name]: value
    })
  }

  resetForm() {
    this.setState({name: '', email: '', subject: '', message: '', consent: false})
  }

  handleSubmit(e) {
    e.preventDefault();
    emailjs.sendForm(apiKeys.SERVICE_ID, apiKeys.TEMPLATE_ID, e.target, apiKeys.USER_ID)
    .then((result) => {
      console.log("Email response: ", result.text);
      this.resetForm();
    }, (error) => {
      console.log("Email response: ", error.text);
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.top}>
          <Hidden mdDown>
            <Header />
          </Hidden>
          <Hidden mdUp>
            <MobileHeader />
          </Hidden>
          <Typography variant="h2" align="center" className={classes.title}>
            Contact Us
          </Typography>
        </div>

        <div className={classes.main}>
          <Container component="main" maxWidth="md">
            <form className={classes.form} onSubmit={this.handleSubmit}>
        
              <TextField
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Full Name"
                autoComplete="name"
                autoFocus
              />
              <TextField
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                autoComplete="email"
                autoFocus
              />
              
              <Typography variant="body1" className={classes.subtitle2} tabIndex={0}>
                  Reason for getting in touch *
              </Typography>
              <Select
                  name="subject"
                  value={this.state.subject}
                  onChange={this.handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="subject"  
              > 
                <MenuItem value="" disabled>Please select</MenuItem>
                <MenuItem value="Customer Support">Customer Support</MenuItem>
                <MenuItem value="Technical Demo">Technical Demo</MenuItem>
                <MenuItem value="Integration Query">Integration Query</MenuItem>
                <MenuItem value="Join Supplier Networ">Join Supplier Network</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>    

              <Typography variant="body1" className={classes.subtitle} tabIndex={0}>
                  Type your message here *
              </Typography>
              <TextField
                  name="message"
                  value={this.state.message}
                  onChange={this.handleChange}
                  variant="outlined"
                  margin="normal"
                  required
                  multiline
                  rows={8}
                  fullWidth
                  id="message"
              />
              
              <Typography variant="body1" className={classes.agree}>
              <FormControlLabel
                  control={<Checkbox 
                            name="consent"
                            checked={this.state.consent}
                            onChange={this.handleChange} 
                            color="primary"
                            required
                          />}
                  label="I agree to receive emails about the latest news and product updates from ApTap."
              />
              </Typography>
              
              <div className={classes.btn}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Submit
                </Button>
              </div>
              
            </form>
          </Container> 
        </div>

        <div className={classes.bottom}>
          <section id="footer">
            <Footer />
          </section>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Contact);