const styles = theme => ({
  // background styles
  top: {
      background: 'linear-gradient(120deg, #020029 10%, #344177 70%)',
  },
  main: {
    background: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: theme.spacing(20, 0, 20),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 0, 5),
    },
    position: 'relative'
  },
  bottom: {
    background: '#4463ae',
  },

  // text styles
  title: {
    color: 'white',
    padding: theme.spacing(5, 20, 5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0, 5),
    },
  },
  subtitle: {
    color: '#777777',
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(-1),
  },
  subtitle2: {
    color: '#777777',
    padding: theme.spacing(1, 0 ,1),
  },
  agree: {
    color: '#777777',
    padding: theme.spacing(2, 0, 2),
  },

  // form styles
  form: {
      width: '100%', // Fix IE 11 issue.     
  },
  submit: {
    width: '200px',
    boxShadow: 'none',
    textTransform: 'unset !important',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  btn: {
    textAlign: 'center',
  },
});

export default styles;