import React, { useEffect } from 'react';
import QrCodeModalDesktop from '../../views/AptapLanding/qrcodemodal/QrCodeModalDesktop';
import QrCodeModalMobile from '../../views/AptapLanding/qrcodemodal/QrCodeModalMobile';

var mixpanel = require('mixpanel-browser');

const NetworkLanding = () => {
    const isMobile = window.innerWidth < 960;

    useEffect(() => {
        mixpanel.track("Web homepage", { "Landing page": "Network" });
    }, []);

    return (
        <>
            {isMobile
                ? <QrCodeModalMobile isMobile={true} currentPage={'Network'} modalOpen />
                : <QrCodeModalDesktop isMobile={false} currentPage={'Network'} modalOpen />
            }
        </>
    );
};

export default NetworkLanding;
