import { Suspense, lazy, useEffect } from 'react';
import { Route, Routes as SwitchRoutes, useLocation } from 'react-router-dom';
import TSBLanding from './containers/TSB/TSBLanding';
import NetworkLanding from './containers/LandingPages/NetworkLanding';
import QrCodeRedirect from './views/AptapLanding/home/QrCodeRedirect';

import Blog from './views/AptapLanding/blog/Blog';
import Banks from './views/AptapLanding/banks/Banks';
import News from './views/AptapLanding/news/News';
import Suppliers from './views/AptapLanding/suppliers/Suppliers';
import Contact from './views/AptapLanding/contact/Contact';
import PrivacyPolicy from './views/AptapLanding/pp-tcs/PrivacyPolicy';
import TandCs from './views/AptapLanding/pp-tcs/TermsAndConditions';
import Article from './views/AptapLanding/blog/article/Article';
import NativeChangePasswordRedirect from './views/MobileRedirect/NativeChangePasswordRedirect';

const MerchantLoginProvider = lazy(() => import('./views/Merchant/Login/MerchantLogin'));
const MerchantDashboardProvider = lazy(() => import('./views/Merchant/Dashboard/MerchantDashboard'));

const LandingHome = lazy(() => import("./views/Landing/Home/LandingHome"));
const LandingAmplify = lazy(() =>
  import("./views/Landing/Amplify/LandingAmplify")
);
const LandingBridge = lazy(() =>
  import("./views/Landing/Bridge/LandingBridge")
);
const LandingCompass = lazy(() =>
  import("./views/Landing/Compass/LandingCompass")
);
const LandingForMerchants = lazy(() =>
  import("./views/Landing/ForMerchants/LandingForMerchants")
);
const LandingForBanks = lazy(() =>
  import("./views/Landing/ForBanks/LandingForBanks")
);
const LandingContactPage = lazy(() =>
  import("./views/Landing/Components/LandingContactPage")
);
const LandingAboutUs = lazy(() =>
  import("./views/Landing/AboutUs/LandingAboutUs")
);

function Routes(props) {
  const location = useLocation();
  useEffect(() => {
    props.setPathname(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <Suspense>
      <SwitchRoutes>

        <Route path="/" exact element={<LandingHome />} />

        <Route path="/unsupported" render={() => { window.location.href = 'unsupported.html' }} />
        <Route path="/tsb" element={<TSBLanding />} />
        <Route path="/network" element={<NetworkLanding />} />

        <Route path="/native-forget-redirect" element={<NativeChangePasswordRedirect />} />

        <Route path="/banks" element={<Banks />} />
        <Route path="/news" element={<News />} />
        <Route path="/suppliers" element={<Suppliers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TandCs />} />

        <Route path="/appqr" element={<QrCodeRedirect />} />
        <Route path="/redirectapp" element={<QrCodeRedirect />} />
        <Route path="/download" element={<QrCodeRedirect />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/article/:id" element={<Article />} />

        <Route path="/amplify/login" element={<MerchantLoginProvider />} />
        <Route path="/amplify/forgotPassword" element={<MerchantLoginProvider />} />
        <Route path="/amplify/verifyUser" element={<MerchantLoginProvider />} />

        <Route path="/amplify/partnerportal" element={<MerchantDashboardProvider />} />
        <Route path="/amplify/partnerportal/provider/*" element={<MerchantDashboardProvider />} />

        <Route path="/amplify/mymarketplace" element={<MerchantDashboardProvider />} />
        <Route path="/amplify/mymarketplace/provider/*" element={<MerchantDashboardProvider />} />

        <Route path="/amplify/analytics" element={<MerchantDashboardProvider />} />
        <Route path="/amplify/profile" element={<MerchantDashboardProvider />} />
        <Route path="/amplify/editor" element={<MerchantDashboardProvider />} />
        <Route path="/amplify/aptrack" element={<MerchantDashboardProvider />} />

        <Route path='/amplify' element={<LandingAmplify />} />
        <Route path='/bridge' element={<LandingBridge />} />
        <Route path='/compass' element={<LandingCompass />} />
        <Route path='/for_banks' element={<LandingForBanks />} />
        <Route path='/for_merchants' element={<LandingForMerchants />} />
        <Route path='/contact-us' element={<LandingContactPage />} />
        <Route path='/about-us' element={<LandingAboutUs />} />

      </SwitchRoutes>
    </Suspense>
  );
}

export default Routes;
