import { makeStyles } from "@mui/styles";
import { aptapColors } from "../../../design/materials/exports";

import { appCommonStyle } from "../../../styles/appCommonStyles";

const styles = makeStyles((theme) => ({
  // main styles
  root: {
    // flexGrow: 1,
  },
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
    position: "relative",
    zIndex: "2",
    padding: "20px 170px",
    // [theme.breakpoints.down('md')]: {
    //   padding: "20px 40px",
    // },
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px",
    },
  },
  toolBar: {
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-around",
    },
  },
  menu: {
    marginLeft: "auto",
  },

  // logo button styles
  aptapButton: {
    width: "150px",
    height: "75px",
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "60px",
    },
  },
  aptapLogo: {
    width: "150px",
    [theme.breakpoints.down("md")]: {
      width: "100px",
    },
  },
  tsbButton: {
    marginLeft: 48,
    width: "120px",
    height: "75px",
    textTransform: "unset !important",
    [theme.breakpoints.down("md")]: {
      width: "100px",
      height: "60px",
    },
  },
  tsbLogo: {
    width: "120px",
    zIndex: -1,
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      width: "90px",
    },
  },

  // login & signup styles
  buttonText: {
    color: "#FFFFFF",
    fontFamily: appCommonStyle.fontFamily,
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "19px",
    lineHeight: "14px",
  },

  signupButton: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: "Work Sans",
    marginRight: 42,
    color: "white",
    width: 140,
    height: 40,
    textTransform: "none",
    borderRadius: 16,
    backgroundColor: aptapColors.LIGHT_RED,
    "&:hover": {
      backgroundColor: aptapColors.LIGHT_RED,
      boxShadow: "none",
    },
    [theme.breakpoints.down("lg")]: {
      width: 120,
      height: 36,
      fontSize: 12,
    },
  },

  loginButton: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: "Work Sans",
    color: "#8951E7",
    border: "1px solid #8951E7",
    width: 140,
    height: 40,
    textTransform: "none",
    borderRadius: 16,
    background: "white",

    "&:hover": {
      background: "white",
      boxShadow: "none",
    },
    [theme.breakpoints.down("lg")]: {
      width: 100,
      height: 36,
      fontSize: 12,
    },
  },
}));

export default styles;
