import { makeStyles } from "@mui/styles";
import { aptapColors } from "../../../design/materials/exports";

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: aptapColors.BACKGROUND_BLUE,
  },
  top: {
    backgroundColor: aptapColors.BACKGROUND_BLUE,
  },
  title: {
    color: "white",
    padding: theme.spacing(5, 20, 5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 0, 5),
    },
  },
  main: {
    backgroundColor: aptapColors.BACKGROUND_BLUE,
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(15, 0, 15),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5, 0, 5),
    },
  },
  card: {
    backgroundColor: aptapColors.BACKGROUND_BLUE,
    marginTop: theme.spacing(-15),
    width: "1200px",
    [theme.breakpoints.down("xl")]: {
      width: "1200px",
    },
    [theme.breakpoints.down("lg")]: {
      width: "960px",
    },
    [theme.breakpoints.down("md")]: {
      width: "600px",
    },
  },
  bottom: {
    backgroundColor: aptapColors.BACKGROUND_BLUE,
  },
  wip: {
    display: "block",
    margin: "0 auto",
  },
}));

export default styles;
