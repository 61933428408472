import { useEffect, useState } from "react";
import "typeface-work-sans";

// Components
import Header from "./Sections/Header";
import Banner from "./Sections/Banner";
import Feature from "./Sections/Feature";
import FAQs from "./Sections/FAQs";
import Testimonial from "../../views/AptapLanding/home/testimonials/Testimonials";
// import Testimonial from './Sections/Testimonial';
import Footer from "../../views/AptapLanding/components/footer/Footer";
// import Footer from './Sections/Footer';
import QrCodeModalDesktop from "../../views/AptapLanding/qrcodemodal/QrCodeModalDesktop";
import QrCodeModalMobile from "../../views/AptapLanding/qrcodemodal/QrCodeModalMobile";

// Material-UI
import { withStyles } from "@mui/styles";
import { aptapColors } from "../../design/materials/exports";

var mixpanel = require("mixpanel-browser");

const styles = (theme) => ({
  root: {},
  section1: {
    borderRadius: "0px 0px 300px 0px",
    [theme.breakpoints.down("md")]: {
      borderRadius: "0px 0px 100px 0px",
    },
  },
  section2: {
    borderRadius: "300px 0px 300px 0px",
    [theme.breakpoints.down("md")]: {
      borderRadius: "100px 0px 100px 0px",
    },
  },
});

const TSBLanding = ({ classes }) => {
  useEffect(() => {
    mixpanel.track("Web homepage", { "Landing page": "TSB" });
  }, []);

  const isMobile = window.innerWidth < 960;
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    if (
      isMobile &&
      url.pathname === "/tsb" &&
      (url.search === "?utm_source=aptapwebp" ||
        url.searchParams.get("utm_source") === 'aptapwebp')
    ) {
      window.location.href = "https://tsb.aptap.co.uk?utm_source=aptapwebp";
    }
  }, [isMobile]);

  const handleModalOpen = (buttonPosition) => {
    setModalOpen(true);
    mixpanel.track("Get the app", { "Button position": buttonPosition });
  };

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: aptapColors.BACKGROUND_BLUE }}
    >
      {isMobile ? (
        <QrCodeModalMobile
          isMobile={true}
          currentPage={"TSB"}
          modalOpen={modalOpen}
          handleModalClose={() => setModalOpen(false)}
        />
      ) : (
        <QrCodeModalDesktop
          isMobile={false}
          currentPage={"TSB"}
          modalOpen={modalOpen}
          handleModalClose={() => setModalOpen(false)}
        />
      )}
      <Header handleModalOpen={handleModalOpen} />
      <Banner handleModalOpen={handleModalOpen} />
      <Feature handleModalOpen={handleModalOpen} />
      <Testimonial />
      <FAQs />
      <Footer isMobile={isMobile} />
    </div>
  );
};

export default withStyles(styles)(TSBLanding);
