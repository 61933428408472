import { OicdConstants } from './../OicdConstants';
import { Log, User, UserManager } from 'oidc-client';

export class AuthService {
    public userManager: UserManager;

    constructor() {
        const settings = {
          authority: OicdConstants.stsAuthority,
          client_id: OicdConstants.clientId,
          redirect_uri: `${OicdConstants.clientRoot}signin-callback.html`,
          silent_redirect_uri: `${OicdConstants.clientRoot}silent-renew.html`,
          // tslint:disable-next-line:object-literal-sort-keys
          post_logout_redirect_uri: `${OicdConstants.clientRoot}`,
          response_type: OicdConstants.responseType,
          scope: OicdConstants.clientScope
        };
        this.userManager = new UserManager(settings);
    
        Log.logger = console;
        Log.level = Log.INFO;
      }

      public getUser(): Promise<User | null> {
        return this.userManager.getUser();
      }
    
      public login(email: string, password: string): Promise<void> {
        this.userManager.settings.extraQueryParams =  {email:email,password:password, appType:localStorage.appType};
        return this.userManager.signinRedirect();
      }

      public loginAfterRegister(email: string, password: string) : Promise<void> {
        this.userManager.settings.extraQueryParams =  {email:email,password:password, appType:localStorage.appType};
        return this.userManager.signinRedirect();
      }
    
      public renewToken(): Promise<User> {
        return this.userManager.signinSilent();
      }
    
      public logout(): Promise<void> {
        localStorage.setItem("logoutAppType",localStorage.appType);
        return this.userManager.signoutRedirect();
      }
}
