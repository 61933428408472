import { makeStyles } from "@mui/styles";
import * as colors from "../../../constants/colors";
import { aptapColors } from "../../../design/materials/exports";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 1920,
        margin: '0 auto',
        paddingTop: 128,
        backgroundColor: aptapColors.BACKGROUND_BLUE,
        paddingBottom: 128,
        [theme.breakpoints.down('md')]: {
            paddingTop: 32,
            paddingBottom: 32
        },
    },
    suppliersContentContainer: {
        backgroundColor: aptapColors.BACKGROUND_BLUE,
        [theme.breakpoints.down('md')]: {
            padding: '0 8px'
        }
    },
    blockContainer: {
        maxWidth: 1920,
        margin: '64px auto 128px',
        padding: '0 200px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            alignItems: "center",
            padding: '0 16px',
            margin: '32px 0 32px',
        },
    },
    blockItem: {
        maxWidth: 448,
        [theme.breakpoints.down('md')]: {
            marginBottom: 24
        }
    },
    blockIcon: {
        width: 156,
        height: 156,
        [theme.breakpoints.down('md')]: {
            width: 103,
            height: 103
        }
    },
    blockTitle: {
        color: '#586072',
        fontSize: 36,
        fontWeight: 500,
        fontFamily: 'Work Sans',
        lineHeight: '42px',
        textAlign: 'center',
        minHeight: 108,
        padding: '16px 0 8px',
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
            lineHeight: '30px',
            minHeight: 'unset',
        }
    },
    blockText: {
        color: '#5C6474',
        fontSize: 24,
        fontWeight: 400,
        fontFamily: 'Work Sans',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: 18,
            lineHeight: '21px',
        },
    },
    blockContainerMargin64: {
        maxWidth: 1920,
        margin: '0 auto 64px',
        padding: '0 200px',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            alignItems: "center",
            padding: '0 16px',
            margin: 0
        }
    },
    header: {
        color: '#7744E2',
        fontSize: 48,
        fontWeight: 700,
        lineHeight: '56px',
        fontFamily: 'Work Sans',
        paddingBottom: 32,
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
            lineHeight: "28px",
            textAlign: 'center',
            paddingBottom: 24
        }

    },
    headerCenter: {
        color: '#CB6CE6',
        fontSize: 48,
        lineHeight: "56px",
        fontWeight: 700,
        fontFamily: 'Work Sans',
        padding: '0 217px 32px',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
            lineHeight: "24px",
            fontWeight: 600,
            padding: '0 0 24px',
        }
    },
    trustedBy: {
        color: '#586072',
        fontSize: 48,
        fontWeight: 700,
        fontFamily: 'Work Sans',
        paddingBottom: 32,
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
            fontWeight: 700,
            textAlign: 'center',
            paddingBottom: 24
        }

    },
    bodyText: {
        color: '#586072',
        fontSize: 24,
        fontWeight: 400,
        fontFamily: 'Work Sans',
        lineHeight: '28px',
        [theme.breakpoints.down('md')]: {
            fontSize: 18,
            lineHeight: '21px',
        },
    },
    suppliersImageContainer: {},
    contactUsButton: {
        fontFamily: 'Work Sans',
        fontWeight: 500,
        fontSize: 18,
        color: 'white',
        width: 228,
        height: 40,
        boxShadow: '0px 0px 2px rgba(0, 14, 40, 0.04), 0px 2px 4px rgba(92, 100, 116, 0.16)',
        textTransform: 'none',
        borderRadius: 52,
        background: 'white',
        '&:hover': {
            background: colors.APTAPPRIMARY,
            boxShadow: 'none',
        },
        [theme.breakpoints.down('lg')]: {
            width: 128,
            height: 32,
            marginBottom: 24,
        },
    },
}));

export default useStyles;



