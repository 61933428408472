import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import ARTICLE_QUERY from "../../queries/article/article";
import ReactMarkdown from "react-markdown";
import Moment from "react-moment";
import "typeface-work-sans";
import "./Article-style.css";

// Components
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import MobileHeader from "../../components/mobileHeader/MobileHeader";

// Material-UI
import useStyles from "./Article-style";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import Typography from "@mui/material/Typography";

const Article = ({ setIsLoading }) => {
  let { id } = useParams();
  const classes = useStyles();

  const { data, loading, error } = useQuery(ARTICLE_QUERY, {
    variables: { id: id },
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [loading]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;

  return (
    <div style={{ background: "#fff" }}>
      {data?.article && (
        <div>
          <Typography variant="h2" align="center" className={classes.title}>
            {data.article.title}
          </Typography>

          <div className={classes.content}>
            <Container>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <ReactMarkdown children={data.article.content} />
                  </Typography>
                </Grid>

                <Grid item xs={12} className={classes.author}>
                  <Typography variant="body1">
                    <Moment format="MMM Do YYYY">
                      {data.article.published_at}
                    </Moment>
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      )}
    </div>
  );
};

const Page = () => {
  const contactRef = React.createRef();
  const [isLoading, setIsLoading] = useState(true);

  const scrollToContactForm = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <Header />
      </Hidden>
      <Hidden mdUp>
        <MobileHeader scrollToContactForm={scrollToContactForm}></MobileHeader>
      </Hidden>
      <Article setIsLoading={setIsLoading} />
      <div ref={contactRef} className={classes.bottom}>
        {!isLoading && (
          <section id="footer">
            <Footer />
          </section>
        )}
      </div>
    </div>
  );
};

export default Page;
