import { aptapTypography } from "./aptapTypography";
import { networkTypography } from "./networkTypography";
import * as aptapColors from "./aptapColors";
import * as networkColors from "./networkColors";
import * as aptapConstants from "./aptapConstants";

var selectedTypography = aptapTypography;

switch (localStorage.appType) {
    case "Network":
        selectedTypography = networkTypography;
        break;
    case "apTap":
    default:
        selectedTypography = aptapTypography;
        break;
}

export const useAppStyles = selectedTypography;
export { aptapTypography, networkTypography, aptapColors, networkColors, aptapConstants };
