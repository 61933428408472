import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

// Material-UI
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';

import * as colors from '../../../../constants/colors';
import { aptapColors } from "../../../../design/materials/exports";

var mixpanel = require('mixpanel-browser');

// NOTE: imgs must be 670 x 500

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '30px'
  },
  card: {
    margin: '0 auto',
    width: '450px',
    height: '675px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '350px',
      height: '520px'
    },
  },
  cardInfo: {
    backgroundColor: aptapColors.BACKGROUND_BLUE,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '16px 0'
  },
  media: {
    height: 0,
    paddingTop: '66%',
  },
  category: {
    color: colors.APTAPPRIMARY,
    fontSize: 18,
    minHeight: 26,
    fontFamily: 'Work Sans'
  },
  title: {
    paddingTop: '10px',
    color: '#000E28',
    fontSize: 36,
    fontWeight: 500,
    fontFamily: 'Work Sans',
    [theme.breakpoints.down('md')]: {
      fontSize: 24
    }
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      color: '#000E28',
    }
  },
  summary: {
    color: '#5C6474',
    fontSize: 18,
    fontFamily: 'Work Sans',
    paddingTop: '10px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    }
  },
  publishDate: {
    color: '#5C6474',
    fontSize: 18,
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: 16
  }
}));

const BlogCard = ({ article }) => {
  const classes = useStyles();
  const imageUrl = "https://blog.aptap.co.uk" + article.image.url

  const handleClick = () => {
    mixpanel.track("Article Page", { "articleId": article.id });
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card} >
        <Link to={`article/${article.id}`} onClick={handleClick}>
          <CardMedia
            className={classes.media}
            image={imageUrl}
            alt={article.image.url}
          />
        </Link>
        <CardContent className={classes.cardInfo}>
          <Typography variant="body1" className={classes.category}>
            {article.category?.name ? article.category.name : ""}
          </Typography>
          <Link to={`article/${article.id}`} onClick={handleClick} className={classes.link}>
            <Typography variant="h3" className={classes.title}>
              {article.title}
            </Typography>
          </Link>
          <Typography variant="body1" className={classes.summary}>
            {article.summary}
          </Typography>
          <Typography className={classes.publishDate}><Moment format="MMM DD YYYY">{article.published_at}</Moment></Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default BlogCard;