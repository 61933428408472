import React from "react";

// Assets
import ApTapLogo from '../../../assets/images/aptap_logo_2022.png';
import tsb_logo from '../../../assets/images/tsb_logo.png';

// Material-UI
import useStyles from './HeaderStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';

const Header = ({ handleModalOpen }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Button 
            onClick={() => window.location.href = "/"}
            className={classes.aptapButton}
          >
            <img src={ApTapLogo} className={classes.aptapLogo} alt="aptap logo" />
          </Button>
          <Button 
            onClick={() => window.location.href = "https://www.tsb.co.uk/managingbills"}
            className={classes.tsbButton}
          >
            <img src={tsb_logo} className={classes.tsbLogo} alt="tsb logo" />
          </Button>
          
          <Hidden smDown>
            <div className={classes.menu}>
              <Button
                variant="contained"
                className={classes.signupButton}
                onClick={() => handleModalOpen('Header')}
              >
                Get the app
              </Button>
            </div>
          </Hidden>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
