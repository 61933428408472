import { makeStyles } from '@mui/styles';

const styles = makeStyles(theme => ({
    root: {
        background: "white",
    },
    top: {
    },
    title: {
        color: '#000E28',
        fontFamily: 'Work Sans',
        paddingTop: theme.spacing(10),
        [theme.breakpoints.down('md')]: {
          paddingTop: theme.spacing(2),
        },
    },
    subtitle: {
        padding: theme.spacing(2, 0, 2),
        fontFamily: 'Work Sans',
        color: '#000E28',
    },
    main: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(0, 20, 10),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(0, 3, 10),
          },
        background: "white",
    },
    text: {
        paddingTop: theme.spacing(3),
        fontSize: '1.1rem',
        color: "#5C6474"
    },
    sectionTitle: {
        paddingTop: theme.spacing(3),
        fontSize: '2rem',
        color: "#000E28"
    },
    sectionSubTitle: {
        paddingTop: theme.spacing(3),
        fontSize: '1.7rem',
        color: "#000E28"
    },
    table: {
        minWidth: 650,
    },
    tablecell: {
        fontSize: '1.1rem',
    },
    ol: {
        counterReset: 'item',
        display: 'table',  
        padding: "0px"
    },
    li: {
        display: 'table-row',
        '&:before': {
            content: 'counters(item, ".") " "',
            counterIncrement: 'item',
            display: 'table-cell', /* aha! */
            textAlign: 'right',
        },
    },
    span: {
        position: 'relative',
        left: '10px',
    },
    bottom: {
    }
}));

export default styles;
