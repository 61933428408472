import React from "react";
import 'typeface-work-sans';

// Material-UI
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';

import useStyles from "./Testimonials-style";


function Testimonial() {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const testimonials = [
    {
      quote: "The web app was easy to use and laid out great. Adding another bank account was also very easy to complete. Great to see everything in one place.", 
      name: "Gayle", 
      initial: "G", 
      colour: "#FF5F42"
    },
    {
      quote: "Having just moved house, I found ApTap great for keeping on top of my money. I can see what bills have come out of my account, as well as what is due, in a simple and easy to understand way.",
      name: "Joe", 
      initial: "J", 
      colour: "#109CF1"
    },
    {
      quote: "My experience with ApTap has been great so far. A simple procedure and quick switch. The TrueLayer integration worked perfectly and I was happy it correctly identified my utilities providers.",
      name: "Asif", 
      initial: "A", 
      colour: "#C869F4"
    },
  ]

  return (
    <div className={classes.root}>
      
      <div className={classes.main}>
        <Typography className={classes.subHeader1Text}>
          Customer testimonials
        </Typography>
      </div>

      <Container className={classes.testimonialContainer} maxWidth={false}>
        <Grid container direction={isMobile ? 'column' : 'row'} justifyContent={isMobile ? 'flex-start' : 'center'} alignItems={isMobile ? 'center' : 'flex-start'} wrap="nowrap">
          {testimonials.map((testimonial, index) => (
            <Grid container item direction="column" wrap="nowrap" className={classes.testimonialContainerItem} key={index}>
              <Grid container item justifyContent="center"><FormatQuoteRoundedIcon className={classes.testimonialIcon} /></Grid>
              <Grid item style={{ minHeight: isMobile ? null : 188 }}><Typography className={classes.bodyText}>{testimonial.quote}</Typography></Grid>
              <Grid item><Typography className={classes.testimonialName}>{testimonial.name}</Typography></Grid>
            </Grid>
          ))}
        </Grid>
      </Container>

    </div>
  );
}

export default Testimonial;

// <Grid item lg={3} md={6} sm={6} xs={12}>
            //   <Card className={classes.blueCard}>
            //     <CardContent className={classes.content}>
            //       <Typography className={classes.bodyText}>
            //         {testimonial.quote}
            //       </Typography>
            //     </CardContent>
            //     <CardActions style={{float:"right"}}>
            //       <Avatar style={{backgroundColor: testimonial.colour}}> {testimonial.initial} </Avatar>
            //       <Typography className={classes.bodyText}>
            //         {testimonial.name}
            //       </Typography>
            //     </CardActions>
            //   </Card>
            // </Grid>