import React from "react";
import styled from "styled-components";
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'

import { aptapConstants, aptapColors, aptapTypography } from "../materials/exports";
import * as colors from "../../constants/colors";
import { appCommonStyle } from '../../styles/appCommonStyles';

const useStyles = makeStyles({
  // root contains the common styles for all button types. Shall apply to all buttons!
  root: {
    borderRadius: localStorage.appType === "Network" ? 4 : 16,
    border: 0,
    color: 'white',
    height: 64,
    width: 200, //TODO: Can we safely use constant values for width? I don't think so
    padding: '0 15px',
    // TODO: Do we want shadows?:
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)'
  },
  rootOutlined: {
    borderRadius: localStorage.appType === "Network" ? 4 : 16,
    // border: "2px solid " + aptapColors,
    // color: 'white',
    height: 64,
    width: 200,
    padding: '0 15px',
  },
  // label contains the common label styles for all button types. Shall apply to all buttons!
  label: {
    ...aptapTypography.TEXT_BUTTON,
    '&:hover': { ...aptapTypography.TEXT_BUTTONBOLD },
    '&:active': { ...aptapTypography.TEXT_BUTTONBOLD }
  },
  rootButtonPrimary: {
    '&:hover': {
      backgroundColor: aptapColors.UIELEMENT_PRIMARYLIGHT2 + aptapConstants.AND_NOTIMPORTANT
    },
    '&:active': {
      backgroundColor: aptapColors.UIELEMENT_PRIMARYDARK1 + aptapConstants.AND_NOTIMPORTANT
    }
  },
  rootButtonSecondary: {
    '&:hover': {
      backgroundColor: aptapColors.UIELEMENT_SECONDARYLIGHT2 + aptapConstants.AND_NOTIMPORTANT
    },
    '&:active': {
      backgroundColor: aptapColors.UIELEMENT_SECONDARYDARK1 + aptapConstants.AND_NOTIMPORTANT
    }
  },
  rootButtonPrimaryOutlined: {
    '&:hover': {
      border: "2px solid " + aptapColors.UIELEMENT_PRIMARYLIGHT2 + aptapConstants.AND_NOTIMPORTANT,
      backgroundColor: "transparent"
    },
    '&:active': {
      // backgroundColor: aptapColors.UIELEMENT_PRIMARYDARK1 + aptapConstants.AND_NOTIMPORTANT
    }
  },
});
const iconForLoadingState = (iconColor) => <CircularProgress style={{ color: iconColor }} size={20} />;

const StyledContainedButton = styled(Button)`
  &.MuiButton-contained.Mui-disabled {
    color: #FFFFFF;
  }
`;

const useOutlinedCardButtonStyles = makeStyles({
  outlinedCardButtonRoot: {
    borderRadius: localStorage.appType === "Network" ? 4 : 16,
    height: props => props.isMobile ? 36 : 40,
    width: props => props.width ? props.width : (props.isMobile ? 'unset' : 140),
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    textTransform: "none"
  },
  outlinedCardButtonLabel: {
    color: props => props.buttontextcolor || "#FFFFFF",
    fontFamily: appCommonStyle.fontFamily,
    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "16.8px",
    letterSpacing: "0.05em"
  },
});

const StyledOutlinedCardButton = styled(Button)`
    &.MuiButton-outlined {
      border: 2px solid ${props => props.buttonbordercolor || '#FFFFFF'};
    }
  `;

export function OUTLINED_CARD_BUTTON(props) {
  const { children, disabled, loading, iconRight, iconLeft, ...rest } = props;
  const classes = useOutlinedCardButtonStyles(props);

  return (
    <StyledOutlinedCardButton
      variant="outlined"
      classes={{
        root: classes.outlinedCardButtonRoot,
        label: classes.outlinedCardButtonLabel
      }}
      style={{
        background: props.buttonbackgroundcolor,
        color: props.buttontextcolor,
      }}
      buttonbordercolor={props.buttonbordercolor}
      {...rest}
    >
      {!loading && iconLeft}
      {!loading && children}
      {!loading && iconRight}
      {loading && iconForLoadingState("#FFFFFF")}
    </StyledOutlinedCardButton>
  );
}

const useCustomizedOutlinedCardButtonStyles = makeStyles({
  outlinedCardButtonRoot: {
    borderRadius: localStorage.appType === "Network" ? 4 : 16,
    borderColor: props => props.borderColor ? props.borderColor : "none",
    borderWidth: props => props.borderWidth ? props.borderWidth : "2px",
    borderStyle: props => props.borderStyle ? props.borderStyle : "solid",
    height: props => props.isMobile ? 36 : 40,
    width: props => props.width ? props.width : (props.isMobile ? 112 : 140),
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
    textTransform: "none"
  },
  outlinedCardButtonLabel: {
    color: props => props.buttontextColor || "#FFFFFF",
    fontFamily: appCommonStyle.fontFamily,
    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "16.8px",
    letterSpacing: "0.05em"
  },
});

export function CUSTOMIZED_OUTLINED_CARD_BUTTON(props) {
  const { children, disabled, loading, iconRight, iconLeft, ...rest } = props;
  const classes = useCustomizedOutlinedCardButtonStyles(props);

  return (
    <Button
      variant="outlined"
      classes={{
        root: classes.outlinedCardButtonRoot,
        label: classes.outlinedCardButtonLabel
      }}
      style={{
        background: props.buttonbackgroundcolor,
      }}
      {...rest}
    >
      {!loading && iconLeft}
      {!loading && children}
      {!loading && iconRight}
      {loading && iconForLoadingState("#FFFFFF")}
    </Button>
  );
}

const useContainedCardButtonStyles = makeStyles({
  containedCardButtonRoot: {
    borderRadius: localStorage.appType === "Network" ? 4 : 16,
    height: props => props.isMobile ? 36 : 40,
    width: props => props.width ? props.width : (props.isMobile ? 'unset' : 140),
    boxShadow: "0px 4px 4px rgba(0, 14, 40, 0.25)",
    textTransform: "none"
  },
  containedCardButtonLabel: {
    color: props => props.buttontextcolor,
    fontFamily: appCommonStyle.fontFamily,
    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "16.8px",
    letterSpacing: "0.05em"
  },
});

export function CONTAINED_CARD_BUTTON({ children, disabled, loading, iconLeft, iconRight, isMobile, ...props }) {
  const classes = useContainedCardButtonStyles(props);

  return (
    <Button
      disabled={disabled}
      variant="contained"
      classes={{
        root: classes.containedCardButtonRoot,
        label: classes.containedCardButtonLabel
      }}
      style={{
        background: colors.WHITE,
      }}
      {...props}
    >
      {!loading && iconLeft}
      {!loading && children}
      {!loading && iconRight}
      {loading && iconForLoadingState(props.buttontextcolor)}
    </Button>
  );
}

const useContainerOutlinedCardButtonStyles = makeStyles({
  containedOutlinedCardButtonRoot: {
    borderRadius: localStorage.appType === "Network" ? 4 : 16,
    height: props => props.isMobile ? 36 : 40,
    width: props => props.width ? props.width : (props.isMobile ? 112 : 140),
    boxShadow: "0px 4px 4px rgba(0, 14, 40, 0.25)",
    textTransform: "none"
  },
  containedOutlinedCardButtonLabel: {
    color: props => props.buttontextcolor,
    fontFamily: appCommonStyle.fontFamily,
    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "16.8px",
    letterSpacing: "0.05em"
  },
});

const StyledContainedOutlinedCardButton = styled(Button)`
    &.MuiButton-outlined {
      border: ${props => localStorage.appType === "Network" ? null : '2px solid ' + props.buttontextcolor};
    }
  `;

export function CONTAINED_OUTLINED_CARD_BUTTON({ children, disabled, loading, iconLeft, iconRight, isMobile, style, ...props }) {
  const classes = useContainerOutlinedCardButtonStyles(props);

  return (
    <StyledContainedOutlinedCardButton
      variant="outlined"
      classes={{
        root: classes.containedOutlinedCardButtonRoot,
        label: classes.containedOutlinedCardButtonLabel
      }}
      style={{
        ...style,
        background: props.buttonbackgroundcolor,
        color: props.buttontextcolor,
      }}
      disabled={props.disabled}
      {...props}
    >
      {!loading && iconLeft}
      {!loading && children}
      {!loading && iconRight}
      {loading && iconForLoadingState("#FFFFFF")}
    </StyledContainedOutlinedCardButton>
  );
}


export function BUTTON_LARGE_PRIMARY(props) {
  const { children, disabled, loading, iconRight, iconLeft, ...rest } = props;
  const classes = useStyles();

  return (
    <StyledContainedButton
      variant="contained"
      style={{
        opacity: disabled ? "50%" : "100%",
        background: aptapColors.UIELEMENT_PRIMARY,
        textTransform: "inherit"
      }}
      classes={{
        root: clsx(classes.root, classes.rootButtonPrimary),
        label: classes.label
      }}
      disabled={disabled}
      {...rest}
    >
      {!loading && iconLeft}
      {!loading && children}
      {!loading && iconRight}
      {loading && iconForLoadingState(aptapColors.UITEXT_WHITE)}
    </StyledContainedButton>
  );
}

export function BUTTON_LARGE_SECONDARY(props) {
  const { children, disabled, loading, iconRight, iconLeft, ...rest } = props;
  const classes = useStyles();

  return (
    <StyledContainedButton
      variant="contained"
      style={{
        opacity: disabled ? "50%" : "100%",
        background: aptapColors.UIELEMENT_SECONDARY,
        textTransform: "inherit"
      }}
      classes={{
        root: clsx(classes.root, classes.rootButtonSecondary),
        label: classes.label
      }}
      disabled={disabled}
      {...rest}
    >
      {!loading && iconLeft}
      {!loading && children}
      {!loading && iconRight}
      {loading && iconForLoadingState(aptapColors.UITEXT_WHITE)}
    </StyledContainedButton>
  );
}

export function BUTTON_LARGE_PRIMARY_OUTLINED(props) {
  const { children, disabled, loading, iconRight, iconLeft, ...rest } = props;
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      style={{
        opacity: disabled ? "50%" : "100%",
        border: "2px solid " + aptapColors.UIELEMENT_PRIMARY,
        color: aptapColors.UITEXT_PRIMARY,
        textTransform: "inherit"
      }}
      classes={{
        root: clsx(classes.rootOutlined, classes.rootButtonPrimaryOutlined),
        label: classes.label
      }}
      disabled={disabled}
      {...rest}
    >
      {!loading && iconLeft}
      {!loading && children}
      {!loading && iconRight}
      {loading && iconForLoadingState(aptapColors.UITEXT_PRIMARY)}
    </Button>
  );
}