import {
    LOGIN_START,
    BANKCONNECT_INFO_CLICK,
    BANKCONNECT_TOBANK_CLICK,
    POSTCODESELECTION_CLICK,
    FORGETPASSWORD_CLICK,
    SIGNUP_CLICK,
    LOGIN_CLICK,
    TODASHBOARD_DETAIL_CLICK,
    TO_PROFILE_DETAIL_CLICK,
    CHANGE_DEAL_STEP,
    CHANGE_MODAL_POSITION,
    CHANGE_BILLING_START_DATE,
} from "../constants/action-types";

const initialState = {
    loginStart: {
        loginObj: {
            username: "",
            password: "",
            rememberMe: false,
            started: false
        }
    },
    bankConnectInfoClick: false,
    postCodeSelectionClick: {
        postcode: "",
        line1: "",
        line2: "",
        line3: "",
        line4: "",
        line5: "",
        addressCombined: ""
    },
    forgetPasswordClick: false,
    signUpClick: false,
    logInClick: false,
    toProfileDetailClick :
        { detailType : 0 , detailData : {} },
    toDashboardDetailClick :
        { detailType : 0 , detailData : {}, dealStep: 0 }, 
        // 1 -> deal card tiklanmasi 
        // 2->      QuotesDetail (DealItem[Broadband]Detail) 
        // 3-> deal offer item tiklanmasi,
        // 4 & 5 -> SwitchMain (DealConfirm[Broad]) 
        // 6 ->     ProfileDetail 
        // 7->      Broadband Add-Ons
    isModalOpen: false,
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_BILLING_START_DATE:
            return {
                ...state,
                toDashboardDetailClick: {
                    ...state.toDashboardDetailClick,
                    billingStartDate: action.payload
                }
            };
        case LOGIN_START:
            return Object.assign({}, state, {
                loginStart: action.payload
            });
        case BANKCONNECT_INFO_CLICK:
            return Object.assign({}, state, {
                bankConnectInfoClick: action.payload
            });
        case BANKCONNECT_TOBANK_CLICK:
            return Object.assign({}, state, {
                bankConnectToBankClick: action.payload
            });
        case POSTCODESELECTION_CLICK:
            return Object.assign({}, state, {
                postCodeSelectionClick: action.payload
            });
        case FORGETPASSWORD_CLICK:
            return Object.assign({}, state, {
                forgetPasswordClick: action.payload
            });
        case SIGNUP_CLICK:
            return Object.assign({}, state, {
                signUpClick: action.payload
            });
        case LOGIN_CLICK:
            return Object.assign({}, state, {
                logInClick: action.payload
            });
        case TODASHBOARD_DETAIL_CLICK :
            return Object.assign({}, state, {
                toDashboardDetailClick: action.payload
            });
        case TO_PROFILE_DETAIL_CLICK:
            return Object.assign({}, state, {
                toProfileDetailClick: action.payload
            });
        case CHANGE_DEAL_STEP:
            return {
                ...state,
                toDashboardDetailClick: {
                    ...state.toDashboardDetailClick,
                    dealStep: action.dealStep
                }
            };
        case CHANGE_MODAL_POSITION:
            return {
                ...state,
                isModalOpen: action.isOpen
            };
        default:
    }
    return state;
}

export default rootReducer;
