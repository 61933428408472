import request from '../network/request';

function logError(model) {
    return request({
        url: `/api/errorBoundries/logError`,
        method: 'POST',
        data: model
    });
}

const GatewayService = { logError }


export default GatewayService
