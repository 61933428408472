// Font weight => 300: Light, 400: Regular/Normal, 500: Medium, 600: Semi-Bold
var appFontFamily = "Work Sans",
    appH1 = {
        fontWeight: 'bold',
        fontSize: 36
    },
    appH2 = {
        fontWeight: 500,
        fontSize: 32
    },
    appH3 = {
        fontWeight: 500,
        fontSize: 26
    },
    appH4 = {
        fontWeight: 500,
        fontSize: 24
    },
    appH5 = {
        fontWeight: 500,
        fontSize: 22
    },
    appH6 = {
        fontWeight: 500,
        fontSize: 20
    },
    appSubtitle1 = {
        fontWeight: 400,
        fontSize: 18
    },
    appSubtitle2 = {
        fontWeight: 500,
        fontSize: 14
    },
    appSubtitle3 = {
        fontWeight: 400,
        fontSize: 14
    },
    appBody1 = {
        fontWeight: 400,
        fontSize: 16
    },
    appBody2 = {
        fontWeight: 400,
        fontSize: 12
    },
    appButton1 = {
        fontWeight: 600,
        fontSize: 20
    },
    appButton2 = {
        fontWeight: 400,
        fontSize: 14
    };

switch (localStorage.appType) {
    case "Network":
        appFontFamily = 'Euclid Circular B';
        appH1 = {
            fontWeight: 500,
            fontSize: 36
        };
        appSubtitle1 = {
            fontWeight: 300,
            fontSize: 18
        };
        appSubtitle2 = {
            fontWeight: 600,
            fontSize: 14
        };
        appBody1 = {
            fontWeight: 300,
            fontSize: 16
        };
        appButton1 = {
            fontWeight: 500,
            fontSize: 20
        }
        break;
    case "TSB":
        appFontFamily = 'TSB Castledown';
        break;
    case 'apTap':
    default:
        appFontFamily = 'Work Sans';
        break;
}

export const appCommonStyle = {
    fontFamily: appFontFamily,
    h1: appH1,
    h2: appH2,
    h3: appH3,
    h4: appH4,
    h5: appH5,
    h6: appH6,
    subtitle1: appSubtitle1,
    subtitle2: appSubtitle2,
    subtitle3: appSubtitle3,
    body1: appBody1,
    body2: appBody2,
    button1: appButton1,
    button2: appButton2
};