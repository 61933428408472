import { makeStyles } from "@mui/styles";

import * as colors from "../../../constants/colors";
import { appCommonStyle } from '../../../styles/appCommonStyles';

const useStyles = makeStyles(theme => ({
  modalHeaderLabel: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontFamily: appCommonStyle.fontFamily,
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "28px",
    lineHeight: "28px",
    textAlign: "center",
    letterSpacing: "0.15px",
    color: colors.WARMINGCOLOR4,
  },
  modalHeaderLabelMobile: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    fontFamily: appCommonStyle.fontFamily,
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "22px",
    lineHeight: "22px",
    textAlign: "center",
    letterSpacing: "0.15px",
    color: colors.WARMINGCOLOR4,
  },
  modalTextLabel: {
    fontFamily: appCommonStyle.fontFamily,
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "22px",
    letterSpacing: "0.15px",
    color: colors.TANGAROA,
    textAlign: "center",
  },
  modalTextLabelMobile: {
    fontFamily: appCommonStyle.fontFamily,
    fontWeight: "normal",
    fontStyle: "normal",
    fontSize: "13px",
    lineHeight: "15px",
    letterSpacing: "0.1px",
    color: colors.TANGAROA,
    textAlign: "center",
  },
  popupDeletingWarnIcon: {
    paddingTop: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
  },
  popupAptapIcon: {
    display: "flex",
    justifyContent: "center",
  },
  alertAvatar: {
    height: "60px",
    width: "72px"
  },
  alertAvatarMobile: {
    height: "34px",
    width: "56px"
  },
  aptapAvatar: {
    height: "60px",
    width: "144px"
  },
  aptapAvatarMobile: {
    height: "40px",
    width: "96px"
  },
  supportButton: {
    color: colors.WHITE,
    background: colors.WARMINGCOLOR4,
    fontFamily: appCommonStyle.fontFamily,
    fontWeight: "500",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "14px",
    border:"unset",
    borderRadius:"16px",
    display: 'inline-block',
    width: "140px",
    height:"40px",
  },
  supportButtonMobile: {
    color: colors.WHITE,
    background: colors.WARMINGCOLOR4,
    fontFamily: appCommonStyle.fontFamily,
    fontWeight: "400",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "13px",
    border:"unset",
    borderRadius:"16px",
    display: 'inline-block',
    width: "124px",
    height:"36px",
  },
}));

export { useStyles };